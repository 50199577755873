import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import { GiTeslaCoil } from "react-icons/gi";
import Bannermain from "../main/Mainbanner";
import SanityClient from "../SanityClient";
import { Link, useNavigate } from "react-router-dom";
import {Cta}  from "../main/Cta";

const Ayurvedic = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage] = useState(3);
  const [error, setError] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    // Fetch data from Sanity
    SanityClient.fetch(
      `*[_type == "abhyanga"]{
        title,
        description,
        image{
          asset->{
            _id,
            url
          }
        },
        slug
      }`
    )
      .then((data) => setServices(data))
      .catch((error) => {
        console.error(error);
        setError("Failed to load services. Please try again later.");
      });
  }, []);

  // Pagination logic
  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = services.slice(indexOfFirstService, indexOfLastService);

  const totalPages = Math.ceil(services.length / servicesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageNumberClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const navigate = useNavigate();
  const PageNavigate =()=>{
    navigate("/therapies")
  }

  return (
    <>
      <div className="grid grid-cols-1 md:gap-5 md:py-8 pb-6 px-[7%] md:px-12">
        <h1
          className="text-lg md:text-2xl mt-[50px] md:mt-0 text-secondary  text-center font-title"
          data-aos="fade-up"
        >
          Our Ayurvedic Therapies
        </h1>
        <h2
          className="text-xl md:text-4xl md:-mt-3 mt-1 font-bold text-primary text-center font-title"
          data-aos="fade-up"
        >
         Holistic Healing for Your True Self
        </h2>
        <div className="font-subtitle">
          <p
            className="text-xs text-gray-800 py-1 md:text-base pb-6 p-3 md:p-0 text-center lg:mx-auto lg:w-1/2 font-subtitle"
            data-aos="fade-up"
          >
            Ayurvedic medicine offers a range of services to promote health and
            treat various health conditions. Here are some common services
            provided by Ayurvedic practitioners:
          </p>
        </div>
        {error ? (
          <div className="text-center text-red-500 mt-4">{error}</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 py-[10px] md:px-[7%] gap-8">
            {currentServices.map((service, index) => (
              <div
                key={index}
                className="bg-white p-5 w-full sm:w-auto border border-l-4 border-l-green-700 hover:shadow-lg gap-2"
                data-aos="fade-right"
              >
                <GiTeslaCoil className="text-5xl bg-gray-100 text-secondary p-2 rounded-full" />
                <h1 className="text-lg md:text-xl font-semibold py-2 font-title">
                  {service.title}
                </h1>
                <p className="text-gray-800 text-sm md:text-base font-subtitle truncate-lines-3">
                  {service.description && service.description[0]?.children?.[0]?.text
                    ? service.description[0].children[0].text
                    : 'Description not available'}
                </p>
                <Link to={`/therapies/${service?.slug?.current}`}>
  <button
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    className="flex gap-2 items-center text-sm font-semibold text-green-700 mt-4 hover:translate-x-2 transition-transform duration-300"
  >
    <BsArrowRight /> <span>READ MORE</span>
  </button>
</Link>

              </div>
            ))}
          </div>
        )}

<div className="flex justify-center items-center    "  onClick={PageNavigate}>
          <button  className="bg-primary text-white text-sm  md:text-base font-semibold py-2 px-6 rounded-lg shadow-lg hover:bg-[#92d14e] transition duration-300">
            View All
          </button>
        </div>

      </div>
    </>
  );
};

export default Ayurvedic;
