import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Pagination from "./Eventpaginaion";
import SanityClient from "../SanityClient";
import { Cta } from "../main/Cta";
import Bannermain from "../main/Mainbanner";

function Event() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  useEffect(() => {
    AOS.init({ duration: 1200 });

    // Fetch event data from Sanity
    const fetchData = async () => {
      try {
        const data = await SanityClient.fetch(`*[_type == "events"]{
          _id,
          eventdate,
          eventimage{
            asset->{
              _id,
              url
            }
          },
          authorname,
          category,
          title,
          discription,
          buttoncontent,
          fblink,
          twitterlink,
          linkedinlink,
          eventslug
        }`);
        setEvents(data);
      } catch (error) {
        console.error("Sanity fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = events.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(events.length / postsPerPage);

  return (
    <>
    <Bannermain/>
    
      <div className="px-[2%] lg:px-[8%] py-2 md:py-10">
        <div className="flex flex-wrap lg:w-full">
          <div className="w-full md:w-[100%] px-2 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              {currentPosts.map((item, index) => (
                <div
                  className="relative bg-white text-black transform hover:-translate-y-2 shadow-2xl transition-transform duration-300 p-[4%] rounded-2xl flex flex-col"
                  key={index}
                  data-aos="fade-up"
                >
                  <div className="relative flex-grow">
                    <img
                      src={item.eventimage?.asset?.url || "/blog.jpg"}
                      alt={item.title}
                      className="w-full h-48 md:h-56 lg:h-64 object-cover object-center rounded-xl"
                    />
                    <div className="absolute -top-4 left-4 bg-white text-secondary font-bold px-2 py-1 rounded">
                      {item.eventdate}
                    </div>
                  </div>
                  <div className="py-4 px-2 flex flex-col flex-grow">
                    <div className="flex-grow">
                      <h2 className="text-sm font-semibold md:text-lg font-title mb-2">
                        {item.title}
                      </h2>
                      <p className="truncate-two-lines md:text-base text-xs font-subtitle text-gray-600 text-justify mb-4">
                        {item.discription}
                      </p>
                    </div>
                    <div className="mt-auto flex flex-wrap items-center justify-between">
                      <Link to={`/event/${item?.eventslug?.current}`}>
                        <button className="bg-primary text-white text-xs sm:text-base font-semibold md:py-3 md:px-6 px-3 py-3 rounded-lg shadow-lg hover:bg-[#92d14e] transition duration-300">
                          {item.buttoncontent}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
      <Cta />
    </>
  );
}

export default Event;
