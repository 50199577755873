import React from 'react';


const OurMissionVision = () => {
  return (
    <div className='px-[7%] py-10 '>
      <div className=' text-lg md:text-2xl text-secondary font-bold text-center  font-title'>
        Our Values
      </div>
      <div className="text-center mb-">
        <p className='text-xl md:text-4xl font-bold text-primary text-center md:py-3 py-1 font-title mb-6'>
          Embrace Our Core Values for Holistic Wellness
        </p>
      </div>
      
      {/* Parent div containing left and right sections */}
      <div 
        className="flex flex-col md:flex-row gap-3 md:gap-3 w-full bg-cover bg-center" 
      >
        
        {/* Left div containing the image */}
        <div className="w-full md:w-1/2 flex flex-col justify-between  gap-5">
          <div className="flex flex-col border rounded-xl  bg-[#faf9f7] p-4 sm:pt-4 md:p-6 h-full">
            <h1 className=" text-lg font-bold text-primary">
              Our Vision
            </h1>
            <p className="md:text-md text-sm text-gray-800 text-justify font-subtitle leading-relaxed">
              Our Vision is to be a leading center of holistic wellness and rejuvenation through the ancient wisdom of Ayurveda. We aspire to transform lives by promoting natural healing, balance, and harmony in mind, body, and spirit.
            </p>
          </div>
          <div className="flex flex-col border rounded-xl  bg-[#faf9f7] p-4 sm:pt-4 md:p-6 h-full">
            <h1 className="text-lg font-bold text-primary">
              Our Mission
            </h1>
            <p className="md:text-md text-sm text-gray-800 text-justify font-subtitle leading-relaxed">
              Our Mission is to provide a sanctuary of healing where ancient Ayurvedic wisdom meets the needs of modern life, fostering physical, mental, and spiritual well-being for all.
            </p>
          </div>
        </div>
        
        {/* Right div containing the text content */}
        
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <img
            src='/cta.jpg'
            alt="Core Values"
            className="h-[300px] md:h-[50vh] w-[300px] md:w-full object-cover rounded-2xl"
          />
        </div>
        
      </div>
    </div>
  );
};

export default OurMissionVision;
