import React, { useState, memo } from "react";
import { Banner } from "../Home/Banner";
import Bannermain from "../main/Mainbanner";
import { FaLocationDot } from "react-icons/fa6";
import { MdMailOutline } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import axios from "axios"; // Import axios for making HTTP requests
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { Link } from "react-router-dom";

const MemoizedBannermain = memo(Bannermain);


const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => { 
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName || !formData.email || !formData.phoneNumber) {
      setErrorMessage("Please fill out required fields.");
      return;
    }

    try {
      const response = await axios.post(
        "https://allmails.whytap.tech/v1/ayurveda/send-contact-email",
        formData
      );
      if (response.data.message === "Contact form email sent successfully!") {
        setSuccessMessage("Message sent successfully!");
        setErrorMessage("");
        setFormData({
          firstName: "",
          email: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });
        toast.success("Contact form sent successfully!");
      }
    } catch (error) {
      console.error("Error sending Contact form:", error);
      // setStatus("Failed to send email. Please try again."); // Show error message
      toast.error("Failed to send contact form. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="mb-5 w-full">
        <MemoizedBannermain/>
      </div>
      {/* Container for Image and Form */}
      <div>
        <footer className="py-4 md:py-8">
          <div className="text-center mb-6">
            <h5 className="text-lg md:text-2xl mt-[50px] md:mt-0 text-secondary  text-center font-title">
              Let Us Help You
            </h5>
            <h1 className="text-xl md:text-4xl font-bold text-primary text-center md:py-2 py-1 font-title">
              Have Queries Before The Appointment?
            </h1>
            <div className="w-20 h-1 mx-auto bg-setext-secondary"></div>
          </div>

          <div className="flex flex-col lg:flex-row justify-center w-full px-4 lg:px-0 gap-4 lg:gap-10 lg:text-left">
            <div className="flex gap-3 rounded-md w-full lg:w-[22%] bg-white p-4 items-center ">
              <div className="flex flex-col gap-3 ">
                <FaPhoneAlt className="text-xl text-[#1173BB]" />
                <TbDeviceLandlinePhone className="text-xl text-[#1173BB]" />
              </div>
              <div className="flex flex-col gap-1">
                <Link
                  to="tel:9443432873"
                  className="text-base font-semibold hover:text-[#1173BB]"
                >
                  +91 94434 32873
                </Link>
                <Link
                  to="tel:04651252560"
                  className="text-base font-semibold hover:text-[#1173BB]"
                >
                  04651 252560
                </Link>
              </div>
            </div>

            <div className="flex gap-3 justify-start items-center px-4 rounded-md w-full lg:w-[22%] py-8 lg:py-0 bg-white ">
              <MdMailOutline className="text-2xl text-[#1173BB]" />
              <div className="text-base font-semibold hover:text-[#1173BB]">
                <Link to="mailto:info@himadriayurvedha.com">
                  info@himadriayurvedha.com
                </Link>
              </div>
            </div>

            <div className="flex gap-3 rounded-md w-full lg:w-[22%] bg-white p-4">
              <FaLocationDot className="text-3xl text-[#1173BB]" />
              <div>
                <a
                  href="https://www.google.com/maps/place/Himadri+Ayurvedic+Hospital/@8.25641,77.306449,14z/data=!4m6!3m5!1s0x3b04f8c1debf1d79:0x386b832dcfcd0706!8m2!3d8.2564099!4d77.3064491!16s%2Fg%2F11rctm0cj?hl=en&entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-base font-semibold text-black hover:text-[#1173BB]"
                >
                  Paraicode, Mulagumoodu Post, Kanyakumari District - 629167
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <div className="flex flex-col lg:flex-row items-stretch gap-8 justify-center py-7 px-3 sm:px-8 md:px-12">
        {/* Left Side - Image */}
        <div className="lg:w-[50%] lg:h-[480px] flex">
          <img
            src="https://images.pexels.com/photos/6663368/pexels-photo-6663368.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="Main"
            className="w-full h-auto object-cover shadow-lg border-white rounded-lg"
          />
        </div>

        {/* Right Side - Form */}
        <div className="w-full lg:w-1/2 flex md:p-5">
          <form
            onSubmit={handleSubmit}
            className="bg-white text-black p-4 md:p-6 rounded-lg shadow-lg w-full"
          >
            <p className="text-2xl font-title text-primary font-bold text-left py-2">
              Get in Touch
            </p>
            {/* Flex Container for Input Pairs */}
            <div className="space-y-4">
              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                <input
                  className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 placeholder:text-xxs sm:placeholder:text-xs"
                  type="text"
                  name="firstName"
                  required
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Name"
                />
                <input
                  className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 placeholder:text-xxs sm:placeholder:text-xs"
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 py-6 sm:space-y-0">
                <input
                  className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 placeholder:text-xxs sm:placeholder:text-xs"
                  type="number"
                  name="phoneNumber"
                  required
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      handleChange(e);
                    }
                  }}
                  placeholder="Phone Number"
                />
                <input
                  className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 placeholder:text-xxs sm:placeholder:text-xs"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                />
              </div>
            </div>
            <textarea
              className="w-full h-20 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none mt-4 placeholder:font-subtitle placeholder-gray-700 placeholder:text-xxs sm:placeholder:text-xs"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Type message here"
            />
            <div className="py-3">
              <button
                type="submit"
                className="w-full md:w-auto h-auto px-3 py-2 sm:h-10 bg-primary text-white sm:text-sm font-subtitle rounded-lg border text-lg transition-transform transform hover:bg-setext-secondary mt-4"
              >
                Send Message
              </button>
            </div>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            {successMessage && (
              <p className="text-green-500">{successMessage}</p>
            )}
          </form>
          {/* Toast Container */}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
          />
        </div>
      </div>

      {/* Google Map */}
      <div className="relative mt-5 h-[500px] w-full">
        <iframe
          title="Google Map"
          className="w-full h-full border-none"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15793.860641320922!2d77.3064491!3d8.2564099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f8c1debf1d79%3A0x386b832dcfcd0706!2sHimadri%20Ayurvedic%20Hospital!5e0!3m2!1sen!2sin!4v1726478075777!5m2!1sen!2sin"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
