import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoMailOutline } from "react-icons/io5";
import { FaFacebook, FaTwitter, FaInstagram, FaPhone } from "react-icons/fa";
import { IoLogoYoutube, IoMdCall } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

export const Topbar = () => {
  return (
    <div className="w-full px-16 py-1  bg-primary hidden md:block  ">
      <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-0">
        <div className="flex flex-col md:flex-row items-center justify-center gap-4 md:gap-5">
          <Link to={`tel:+91 94434 32873`}>
            <p className="flex items-center gap-1 text-center md:text-left text-white hover:text-[#92d14e]">
              <IoMdCall className="font-extralight text-2xl md:text-lg " />
              <span className=" font-content text-sm md:text-md font-normal ">
                +91 94434 32873
              </span>
            </p>
          </Link>
          <div className="flex items-center space-x-2 text-white hover:text-[#92d14e] ">
            <IoMailOutline className="text-primaryColor text-lg lg:text-2xl cursor-pointer" />
            <div>
              <Link to={`mailto:info@himadriayurvedha.com`}>
                <p className="text-xs lg:text-sm font-semibold cursor-pointer hover:text-primaryColor">
                info@himadriayurvedha.com
                </p>
              </Link>
            </div>
          </div>
          
        </div>
        <div className="flex items-center justify-center gap-4 md:gap-3 ">
        <Link to="https://www.facebook.com/HimadriAyurvedicHospital/" target="_blank" rel="noopener noreferrer">
  <FaFacebook 
    className="text-primary bg-white  rounded-full size-7 text-xl p-1 
               transform transition duration-300 hover:scale-110" 
  />
</Link>

          <Link to="https://www.youtube.com/@HimadriAyurvedicHospital" target="_blank" rel="noopener noreferrer">
            <IoLogoYoutube className="text-primary bg-white rounded-full size-7 text-xl p-1  transform transition duration-300 hover:scale-110" />
          </Link>
          <Link to="https://www.instagram.com/himadriayurvedichospital/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-primary bg-white rounded-full size-7 text-xl p-1  transform transition duration-300 hover:scale-110" />
          </Link>
        </div>
      </div>
    </div>
  );
};
