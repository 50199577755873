import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IoChevronForward, IoClose } from "react-icons/io5";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import SanityClient from "../SanityClient";
const Navbar = () => {
  const [data, setData] = useState([]);
  const [treatmentsSubsections, setTreatmentsSubsections] = useState([]);
  const [nestedSubsections, setNestedSubsections] = useState({});
  const [treatmentsFromSanity,settreatmentsFromSanity] = useState([])


  useEffect(() => {
    SanityClient.fetch(
      `*[_type == "abhyanga"]{
        title,
        slug,
        therapiesCategory
      }`
    ).then((fetchedData) => {
      // Transform fetched data into subsections and nested structure
      const categoryMap = {};
      const categoryTitles = [];
      fetchedData?.forEach((item) => {
        const { title, slug, therapiesCategory } = item;
        // Add the category if it doesn't exist yet
        if (!categoryMap[therapiesCategory]) {
          categoryMap[therapiesCategory] = [];
          categoryTitles.push(therapiesCategory);
        }
        // Push the treatment details into the respective category
        categoryMap[therapiesCategory]?.push({
          title: title,
          slug: slug,
        });
      });
      setTherapiesSubsections(categoryTitles);
      setNestedTherapies(categoryMap);
      // console.log(fetchedData, "Fetched Data");
    });
  }, []);

  
  useEffect(() => {
    SanityClient.fetch(
      `*[_type == "treatmentDetails"]{
      treatmentTitle,
      treatmentSlug,
      treatmentCategory
    }`
    ).then((fetchedData) => {
      // Transform fetched data into subsections and nested structure
      const categoryMap = {};
      const categoryTitles = [];
      fetchedData?.forEach((item) => {
        const { treatmentTitle, treatmentSlug, treatmentCategory } = item;
        // Add the category if it doesn't exist yet
        if (!categoryMap[treatmentCategory]) {
          categoryMap[treatmentCategory] = [];
          categoryTitles?.push(treatmentCategory);
        }
        // Push the treatment details into the respective category
        categoryMap[treatmentCategory]?.push({
          treatmentTitle: treatmentTitle,
          treatmentSlug: treatmentSlug,
        });
      });
      setTreatmentsSubsections(categoryTitles);
      setNestedSubsections(categoryMap);
      // console.log(fetchedData, "Fetched Data");
    });
  }, []);
  useEffect(() => {
    SanityClient.fetch(
      `*[_type == "treatmentsubdetails"]{
      title,
      treatmentsubslug
    }`
    ).then((fetchedData) => {
      settreatmentsFromSanity(fetchedData)
      // console.log(fetchedData,"datafrom");
    });
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(null);
  const [subSubMenuOpen, setSubSubMenuOpen] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleNavigation = (path) => {
    navigate(path);
    scrollToTop();
    setIsOpen(false);
  };
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const handleSubMenuHover = (index) => {
    setSubMenuOpen(index);
    setSubSubMenuOpen(null);
  };
  const handleSubSubMenuHover = (index) => {
    setSubSubMenuOpen(index);
  };
  const closeMenus = () => {
    setSubMenuOpen(null);
    setSubSubMenuOpen(null);
  };

  const [therapiesSubsections, setTherapiesSubsections] = useState([]);
const [nestedTherapies, setNestedTherapies] = useState([]);

  return (
    <div className="sticky top-0 font-medium font-text text-md lg:h-[13vh] z-50 font-subtitle ">
      <div className="bg-white drop-shadow-md shadow-primaryColor flex flex-row justify-cente w-full items-cente px-6 md:px-8 lg:px-16">
        {/* Mobile Menu Toggle */}
        {isOpen === false ? (
          <div className="flex items-center justify-between w-full lg:hidden ">
            <div
              className="flex items-center mb-2 lg:mb-0  lg:hidden"
              data-aos="fade-right"
            >
              <img
                src='/himadri logo 350x 150.png'
                alt="Logo"
                className="h-[50px] md:h-[60px] w-auto cursor-pointer -ml-4  transform transition duration-300 hover:scale-110"
                onClick={() => handleNavigation("/")}
              />
              
            </div>
            <FaBars
              className="text-2xl my-auto lg:hidden"
              onClick={() => setIsOpen(true)}
            />
          </div>
        ) : (
          <div className="w-full flex items-center mt-5 justify-end">
            <IoClose
              className="text-2xl my-auto lg:hidden"
              onClick={() => setIsOpen(false)}
            />
          </div>
        )}

        {/* Desktop Menu */}
        <div
          className="lg:flex  hidden justify-betwee w-full items-center"
          data-aos="fade-right"
        >
          <div className="h-[60px] lg:h-[75px] w-full  cursor-pointer">
            <img
              src='/himadri logo 350x 150.png'
              alt="Logo"
              className="h-[60px] lg:h-[76px] w-auto cursor-pointer  transform transition duration-300 hover:scale-110"
              onClick={() => handleNavigation("/")}
            /> 
            <Link to="/">
            {/* <h1 className="ml-3 pt-4 font-subtitle text-[#92d14e] font-bold text-2xl">Himadri</h1>
            <h2 className="w-full text-sm text-prbg-primary font-subtitle">Ayurvedic Hospital</h2> */}
            
            </Link>
          </div>
          <div className="lg:flex  hidden gap-3  w-full justify-end items-center">
            <Link
              to="/"
              className={` relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-transparent ${
                location.pathname.startsWith("/home")
                  ? "bg-primary text-white border-primary p-2 shadow-lg scale-105"
                  : "hover:text-primary border-transparent text-gray-700 p-2 hover:scale-105 hover:shadow-md"
              }`}
              onClick={() => handleNavigation("/")}
            >
              Home
            </Link>

            <Link
              to="/aboutus"
              className={` relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-transparent ${
                location.pathname.startsWith("/aboutus")
                  ? "bg-primary text-white border-prbg-primary p-2 shadow-lg scale-105"
                  : "hover:text-prbg-primary border-transparent text-gray-700 p-2 hover:scale-105 hover:shadow-md"
              }`}
              onClick={() => handleNavigation("/aboutus")}
            >
              About Us
            </Link>
            <div className="relative group" onMouseLeave={closeMenus}>
  <Link
    to="/treatment"
    className={`relative overflow-hidden rounded-lg transition-all capitalize duration-300 ease-in-out border-transparent ${
      location.pathname.startsWith("/treatment")
        ? "bg-primary text-white border-primary p-2 shadow-lg scale-105"
        : "hover:text-primary border-transparent text-gray-700 p-2 hover:scale-105 hover:shadow-md"
    }`}
    onClick={() => {
      handleNavigation("/treatment");
      handleSubMenuHover(-1); // Close submenu after navigation
      handleSubSubMenuHover(-1); // Close sub-submenu after navigation
    }}
    onMouseEnter={() => handleSubMenuHover(0)}
  >
    Treatments
  </Link>
  {subMenuOpen === 0 && (
    <div className="absolute top-full text-sm capitalize -left-[500px] p-4 z-50">
      <div className="bg-white grid grid-cols-3 capitalize gap-3 w-[900px] p-4 mt-2 border">
        {treatmentsSubsections?.map((treatment, index) => {
          const matchingTreatment = treatmentsFromSanity?.find(
            (treatmentValue) => treatmentValue?.title === treatment
          );
          return (
            <div key={index} className="relative capitalize">
              <div
                className={`p-1 border-b-2  border-dotted cursor-pointer capitalize flex justify-between items-center transition-colors duration-200 ${
                  subSubMenuOpen === index
                    ? "bg-primary text-white"
                    : "hover:bg-gray-100 hover:text-primary"
                }`}
                onMouseEnter={() => handleSubSubMenuHover(-1)} // Ensure sub-submenu doesn't open on hover
              >
                <button
                  onClick={() => {
                    handleNavigation(`/treatment/${matchingTreatment?.treatmentsubslug?.current}`);
                    handleSubMenuHover(-1); // Close submenu after navigation
                    handleSubSubMenuHover(-1); // Close sub-submenu after navigation
                  }}
                >
                  {treatment}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the hover event
                    handleSubSubMenuHover(subSubMenuOpen === index ? -1 : index); // Toggle sub-submenu
                  }}
                >
                  <svg
                    className={`w-4 h-4 transition-transform ${
                      subSubMenuOpen === index ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </div>
              {subSubMenuOpen === index && (
                <div className="absolute top-8 w-full grid grid-cols-1 gap-4 mt-0 p-4 bg-gray-100 shadow-lg z-50">
                  {nestedSubsections[treatment] &&
                    nestedSubsections[treatment]?.map((subsection, subIndex) => (
                      <Link
                        key={subIndex}
                        to={`/treatments/${subsection?.treatmentSlug?.current}`}
                        onClick={() => {
                          // Close the submenus after clicking the link
                          handleNavigation(`/treatments/${subsection?.treatmentSlug?.current}`);
                          handleSubMenuHover(-1);
                          handleSubSubMenuHover(-1);
                        }}
                      >
                        <div
                          className={`p-2 cursor-pointer transition-colors duration-200 ${
                            location.pathname === `/treatments/${subsection?.treatmentSlug?.current}`
                              ? "bg-primary text-white"
                              : "hover:bg-white hover:text-primary"
                          }`}
                        >
                          {subsection?.treatmentTitle}
                        </div>
                      </Link>
                    ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  )}
</div>




<div className="relative group" onMouseLeave={closeMenus}>
  <Link
    to="/therapies"
    className={`relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-transparent ${
      location.pathname.startsWith("/ayurvedic")
        ? "bg-primary text-white border-prbg-primary p-2 shadow-lg scale-105"
        : "hover:text-prbg-primary border-transparent text-gray-700 p-2 hover:scale-105 hover:shadow-md"
    }`}
    onClick={() => {
      handleNavigation("/ayurvedic");
      handleSubMenuHover(-1); // Close submenu after navigation
    }}
    onMouseEnter={() => handleSubMenuHover(1)} // Assuming 1 is the index for "Therapies"
  >
    Therapies
  </Link>
  
  {subMenuOpen === 1 && (
    <div className="absolute top-full text-sm -left-[400px] p-4 z-50">
      <ul className="bg-white grid grid-cols-3 gap-3 w-[700px] p-4 mt-2 border">
        {therapiesSubsections?.map((category, index) => (
          <li
            key={index}
            className="relative"
            onMouseEnter={() => handleSubSubMenuHover(index)}
            onMouseLeave={() => handleSubSubMenuHover(null)}
          >
            <ul className="flex flex-col text-gray-700">
              {nestedTherapies[category]?.map((subsection, subIndex) => (
                <Link
                  to={`/therapies/${subsection?.slug?.current}`}
                  key={subIndex}
                  onClick={() => {
                    handleNavigation(`/therapies/${subsection?.slug?.current}`);
                    handleSubMenuHover(-1); // Close submenu after navigation
                    handleSubSubMenuHover(null); // Close sub-submenu after navigation
                  }}
                >
                  <li className="p-2 border-b-2 border-dashed hover:bg-white cursor-pointer">
                    {subsection?.title}
                  </li>
                </Link>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )}
</div>

          
            <Link
              to="/Blog"
              className={` relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-transparent ${
                location.pathname.startsWith("/Blog")
                  ? "bg-primary text-white border-prbg-primary p-2 shadow-lg scale-105"
                  : "hover:text-prbg-primary border-transparent text-gray-700 p-2 hover:scale-105 hover:shadow-md"
              }`}
              onClick={() => handleNavigation("/Blog")}
            >
              Blog
            </Link>
           
            <Link
              to="/contact"
              className={` relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-transparent ${
                location.pathname.startsWith("/contact")
                  ? "bg-primary text-white border-prbg-primary p-2 shadow-lg scale-105"
                  : "hover:text-prbg-primary border-transparent text-gray-700 p-2 hover:scale-105 hover:shadow-md"
              }`}
              onClick={() => handleNavigation("/contact")}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
  className={`lg:hidden fixed top-0 left-0 h-full w-full bg-white z-50 p-5 transform ${
    isOpen ? "translate-x-0" : "-translate-x-full"
  } transition-transform duration-300 ease-in-out`}
>
  <div className="flex flex-col gap-5 pl-5 mt-10 h-[calc(100vh)] overflow-y-auto">
    {/* Close Icon */}
    <div className="flex justify-end">
      <IoClose
        className="text-2xl cursor-pointer"
        onClick={() => setIsOpen(false)}
      />
    </div>

    {/* Home Link */}
    <Link
      to="/"
      className={`relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-b-2 ${
        location.pathname === "/"
          ? "bg-primary text-white border-b-prbg-primary p-2 shadow-lg scale-105"
          : "hover:text-prbg-primary border-b-gray-200 text-gray-700 p-2 hover:scale-105 hover:shadow-md"
      }`}
      onClick={() => handleNavigation("/")}
    >
      Home
    </Link>

    {/* About Link */}
    <Link
      to="/aboutus"
      className={`relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-b-2 ${
        location.pathname === "/about"
          ? "bg-primary text-white border-b-prbg-primary p-2 shadow-lg scale-105"
          : "hover:text-prbg-primary border-b-gray-200 text-gray-700 p-2 hover:scale-105 hover:shadow-md"
      }`}
      onClick={() => handleNavigation("/about")}
    >
      About Us
    </Link>

    {/* Treatments with Submenu */}
     <div className="relative">
      <div
        className={`relative  overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-b-2 flex items-center justify-between ${
          location.pathname.startsWith("/treatment")
            ? "bg-primary text-white border-b-prbg-primary p-2 shadow-lg scale-105"
            : "hover:text-prbg-primary border-b-gray-200 text-gray-700 p-2 hover:scale-105 hover:shadow-md"
        }`}
        onClick={() => handleNavigation("/treatment")}
      >
        <span>Treatments</span>
        <IoChevronForward
          className={`ml-2 text-xl transition-transform ${
            subMenuOpen === 0 ? "rotate-90" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setSubMenuOpen(subMenuOpen === 0 ? null : 0);
            window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
          }}
        />
      </div>
      {subMenuOpen === 0 && (
        <div className="mt-2 p-4 w-full bg-white shadow-lg z-50">
          <ul className="flex flex-col text-gray-700">
            {treatmentsSubsections.map((treatment, index) => (
              <li key={index} className="relative border-b-2 border-gray-200">
                <div
                  className="flex justify-between p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setSubSubMenuOpen(
                      subSubMenuOpen === index ? null : index
                    );
                    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
                  }}
                >
                  <span>{treatment}</span>
                  <IoChevronForward
                    className={`ml-2 text-xl transition-transform ${
                      subSubMenuOpen === index ? "rotate-90" : ""
                    }`}
                  />
                </div>
                {subSubMenuOpen === index && (
                  <div className="mt-2 p-4 w-full bg-gray-100 shadow-lg z-50">
                    <ul className="flex flex-col text-gray-700">
                      {nestedSubsections[treatment]?.map(
                        (subsection, subIndex) => (
                          <Link
                            key={subIndex}
                            to={`/treatments/${subsection?.treatmentSlug?.current}`}
                            onClick={() => {
                              setIsOpen(false);
                              window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
                            }}
                          >
                            <li className="p-2 hover:bg-gray-100 cursor-pointer">
                              {subsection?.treatmentTitle}
                            </li>
                          </Link>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>

    {/* Other Links */}
    <div  className="relative">
  {/* Main Therapies Button */}
  <div  className={`    
    relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-b-2 flex items-center  justify-between`} >
    {/* Left: Therapies Link */}
    <Link
    to='/therapies'
      className={` ${
        location.pathname.startsWith("/ayurvedic")
           ? "bg-primary text-white border-b-prbg-primary p-2  scale-105"
            : "hover:text-prbg-primary border-b-gray-200 text-gray-700 p-2 hover:scale-105 hover:shadow-md"
      }`}
      onClick={() => handleNavigation("/ayurvedic")}
    >
      Therapies
    </Link>

    {/* Right: Arrow for Submenu Toggle */}
   <button
  className="p-2 text-black text-lg focus:outline-none flex items-center"
  onClick={() => setSubMenuOpen(subMenuOpen === 1 ? null : 1)} // Toggle submenu
>
  <span className="text-lg">
    {subMenuOpen === 1 ? (
      <IoChevronForward className="rotate-90 transition-transform duration-300" /> // Rotated when open
    ) : (
      <IoChevronForward className="transition-transform duration-300" /> // Default forward icon
    )}
  </span>
  
</button>
  </div>

  {/* Submenu */}
  {subMenuOpen === 1 && (
    <div className="text-sm  z-50 md:hidden">
      <ul className="bg-white grid grid-cols-1 gap-3 w-full p-4 mt-2 border">
        {therapiesSubsections?.map((category, index) => (
          <li key={index} className="relative">
            <ul className="flex flex-col text-gray-700">
              {nestedTherapies[category]?.map((subsection, subIndex) => (
                <Link
                  to={`/therapies/${subsection?.slug?.current}`}
                  key={subIndex}
                  onClick={() => {
                    handleNavigation(`/therapies/${subsection?.slug?.current}`);
                    setSubMenuOpen(null); // Auto-close the submenu
                  }}
                >
                  <li className="p-2 border-b-2 border-dashed hover:bg-gray-100 cursor-pointer">
                    {subsection?.title}
                  </li>
                </Link>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )}
</div>

    
    
    <Link
      to="/Blog"
      className={`relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-b-2 ${
        location.pathname.startsWith("/Blog")
          ? "bg-primary text-white border-b-prbg-primary p-2 shadow-lg scale-105"
          : "hover:text-prbg-primary border-b-gray-200 text-gray-700 p-2 hover:scale-105 hover:shadow-md"
      }`}
      onClick={() => handleNavigation("/Blog")}
    >
      Blog
    </Link>
    
    <Link
      to="/contact"
      className={`relative overflow-hidden rounded-lg transition-all duration-300 ease-in-out border-b-2 ${
        location.pathname.startsWith("/contact")
          ? "bg-primary text-white border-b-prbg-primary p-2 shadow-lg scale-105"
          : "hover:text-prbg-primary border-b-gray-200 text-gray-700 p-2 hover:scale-105 hover:shadow-md"
      }`}
      onClick={() => handleNavigation("/contact")}
    >
      Contact Us
    </Link>
  </div>
</div>



    </div>
  );
};
export default Navbar;
