import React, { useEffect, useState } from 'react';
import client from '../SanityClient'; // Adjust the path as needed
import { Link } from 'react-router-dom';
import Bannermain from '../main/Mainbanner';
import { Cta } from '../main/Cta';

export const TreatmentPage = () => {
  const [treatments, setTreatments] = useState([]);
  

  useEffect(() => {
    const fetchTreatments = async () => {
      const query = `*[_type == "treatmentDetails"]{
        _id,
        treatmentSlug,
        bannerImage {
          asset -> {
            url
          }
        },
        treatmentTitle,
        treatmentDescription,
        treatmentCategory
      }`;

      const data = await client.fetch(query);
      setTreatments(data);
    };

    fetchTreatments();
  }, []);

  return (
    <>
      <Bannermain />
      <section className="py-12">
        <p className='text-xl md:text-4xl font-bold text-primary text-center md:py-2 py-1 font-title'>
          Enrich Your Health with Herbal
        </p>
        <p className='text-xs text-gray-800 py-1 md:text-base pb-6 p-3 md:p-0 text-center lg:mx-auto lg:w-1/2 font-subtitle'>
          Ayurvedic treatments encompass a wide range of therapies and modalities. Here are some of the commonly practiced types of Ayurvedic treatments:
        </p>
        <div className="lg:px-[10%] py-5 px-[3%]">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {treatments.map((treatment) => (
              <div key={treatment._id} className="bg-white shadow-md rounded-lg overflow-hidden">
                <img
                  src={treatment.bannerImage?.asset?.url || '/default-image.jpg'}
                  alt={treatment.treatmentTitle}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6 border-2 border-l-red-600">
                  <h3 className="text-lg md:text-xl font-semibold mb-2 font-title">
                    {treatment.treatmentTitle}
                  </h3>
                  <p className="text-gray-700 md:text-base text-xs font-subtitle line-clamp-2">
                    {treatment.treatmentDescription}
                  </p>
                  <Link to={`/treatments/${treatment?.treatmentSlug?.current}`}>
  <button
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    className="text-white bg-primary px-3 py-1.5 rounded-md hover:bg-[#1c4f7d] transition duration-300 text-xs mt-4"
  >
    Know More
  </button>
</Link>

                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className='bg-gray-100'><Cta/></div>

    </>
  );
};
