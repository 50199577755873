import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaRegUser,
} from "react-icons/fa"
import Bannermain from "../main/Mainbanner";
import sanityClient from "../SanityClient";
import { MdCategory } from "react-icons/md";
import { FaCircleLeft } from "react-icons/fa6";
import { Cta } from "../main/Cta";

const Blogdetails = () => {
  const [blogData, setBlogData] = useState(null);
  const navigate = useNavigate();
  const PageNavigate = () => {
    navigate("/Blog");
  };
  const {blogslug} = useParams()
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
    sanityClient
      .fetch(
        `
      *[_type == "blogs" && blogslug.current == ${JSON.stringify(blogslug)}]{
        blogdate,
        blogimage{
          asset->{
            _id,
            url
          }
        },
        authorname,
        category,
        title,
        discription,
        fblink,
        twitterlink,
        linkedinlink,
        blogslug
      }
    `
      )
      .then((data) => setBlogData(data[0]))
      .catch(console.error);
  }, []);

  const handleClick = () => {
    navigate("/contact");
  };

  if (!blogData) return <div>Loading...</div>;

  return (
    <>
      <Bannermain />
      <div className="px-[1%] py-5 bg-gray-100 sm:px-6 lg:px-[8%] lg:py-8">
        <div className="flex flex-col lg:flex-row justify-center items-start h-full space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Main Blog Section */}
          <div className="w-full px-2 lg:px-4 mb-8">
            <div
              className="relative bg-white md:p-6  p-2 rounded-2xl text-black shadow-2xl "
              data-aos="fade-up"
            >
              <div className="relative">
                <img
                  src={blogData.blogimage.asset.url}
                  alt={blogData.title}
                  className="w-full h-64 sm:h-56 lg:h-96 object-cover object-center"
                />
                <div className="absolute top-3 left-3 bg-[#92d14e] font-bold md:text-lg text-white text-xs p-2 rounded">
                  {blogData.blogdate}
                </div>
              </div>
              <div className="px-2 py-[3.5%] w-full">
                {/* <ul className="flex sm:flex-wrap items-center text-primary  mb-4 w-full  gap-3 md:gap-9">
                  <li className="flex items-center gap-1">
                    <FaRegUser className="text-[#92d14e] sm:text-xl " />
                    <span className=" text-xs lg:text-base text-gray-600">
                      {blogData.authorname}
                    </span>
                  </li>
                  <li className="flex items-center gap-1">
                    <MdCategory className="text-[#92d14e] sm:text-xl" />
                    <span className="text-xs lg:text-base text-gray-600">
                      {blogData.category}
                    </span>
                  </li>
                </ul> */}
                <h2 className="lg:text-3xl  font-bold mb-4 lg:mb-6  md:text-4xl  font-title text-primary text-base ">
                  {blogData.title}
                </h2>
                <p className="text-justify md:text-base  md:text leading-relaxed font-subtitle text-gray-800 text-xs  ">
                  {blogData.discription}
                </p>
                <div className="flex flex-wrap  items-center justify-between gap-2  sm:gap-4 mt-[3%] -mb-[3%] ">
                  <div
                  className="  bg-pritext-primary text-primary text-xs sm:text-base flex items-center p-2 gap-1 font-semibold md:py-4 md:px-4 justify-start  rounded-lg shadow-lg cursor-pointer hover:bg-primary hover:text-white transition duration-300"                  onClick={PageNavigate}

                  >
                    <p>
                      <FaCircleLeft />
                    </p>
                    <p>GO BACK</p>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cta/>
    </>
  );
};

export default Blogdetails;
