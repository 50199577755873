import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import backgroundImg from '../../src/banner2.jpg';
import backgroundImg1 from '../assets/back.jpg'

export const Banner = () => {
  const navigate = useNavigate();

  const PageNavigate = () => {
    navigate("/contact");
  };

  const bannerContents = [
    // {
    //   title: "Experience Nature's Healing Power",
    //   subtitle: "Your Health, Our Priority",
    //   description:
    //     "At Himadri Ayurvedic Hospital, we provide tailored treatments that harmonize your body and mind through Ayurveda's ancient wisdom.",
    //   hasBackgroundImage: false, // No background image for the first slide
    //   leftImage: '/banner.jpeg', // The image to display on the left side
    // },
    {
      title: "Welcome to Himadri Ayurvedic Hospital",
      subtitle: "Care for Yourself with Nature's Touch",
      description:
        "Ayurveda treatment is a holistic approach to health and well-being based on the principles of Ayurvedic medicine. It aims to restore balance and harmony in the body.",
      bgImage: backgroundImg, // Background image for the second slide
      hasBackgroundImage: true,
    },
    {
      title: "Experience Nature's Healing Power",
      subtitle: "Your Health, Our Priority",
      description:
      "At Himadri Ayurvedic Hospital, we provide tailored treatments that harmonize your body and mind through Ayurveda's ancient wisdom.",
      bgImage: backgroundImg1, // Background image for the second slide
      hasBackgroundImage: true,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false, // Add this line to remove arrows
  };
  

  return (
    <Slider {...settings} className='-mt-10'>
      {bannerContents.map((banner, index) => (
        <div key={index} className="h-[400px] md:h-[550px] w-full">
          {banner.hasBackgroundImage ? (
            <div
              className="h-full w-full flex flex-col gap-2 md:bg-cover bg-center items-start text-white"
              style={{
                backgroundImage: `url(${banner.bgImage})`,
              }}
            >
              <div className="bg-black bg-opacity-40 w-full h-full flex flex-col justify-center px-4 sm:px-8 lg:px-16">
                <div className="rounded-lg">
                  <h1 className="text-sm sm:text-lg md:text-xl font-light pt-8 pb-4 font-title">
                    {banner.title}
                  </h1>
                  <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl w-full md:w-[60%] font-bold mb-4 md:mb-6 font-title">
                    {banner.subtitle}
                  </h2>
                  <p className="text-xs sm:text-base md:text-lg w-full md:w-[70%] lg:w-[40%] text-justify font-subtitle">
                    {banner.description}
                  </p>
                </div>
                <div
                  className="bg-white hover:bg-primary text-center rounded-lg text-primary hover:text-white mt-6 md:mt-6 w-fit py-2 sm:py-3 px-4"
                  onClick={PageNavigate}
                >
                  <button className="text-xs md:text-base font-medium ">
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
          ) : (

            <div
  className="h-full w-full flex flex-col md:flex-row items-center justify-between bg-cover bg-center"
 
>
  <div className="w-full">
    <div className="flex flex-col md:flex-row items-center justify-center gap-4 md:px-16 w-full">
      {/* Right side - Image and Text */}
      <div className="w-full lg:w-1/2 flex flex-col text-left text-white p-4 sm:p-8">
        <h1 className="text-sm sm:text-lg md:text-xl font-light pb-4 font-title">
          {banner.title}
        </h1>
        <h2 className="text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 font-title">
          {banner.subtitle}
        </h2>
        <p className="text-xs sm:text-base md:text-lg text-justify font-subtitle mb-6">
          {banner.description}
        </p>
        <div
          className="bg-[#1173BB] hover:bg-[#73a73c] text-center rounded-lg py-2 sm:py-3 px-4 w-[75%] sm:w-[50%] md:w-[30%] mx-auto md:mx-0"
          onClick={PageNavigate}
        >
          <button className="text-xs md:text-base font-medium text-white">
            Enquire Now
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

          
          )}
        </div>
      ))}
    </Slider>
  );
};
