import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaAngleRight,
  FaAddressBook,
  FaMailBulk,
  FaLinkedin,
  FaClock,
  FaArrowAltCircleRight,
  FaHome,
} from "react-icons/fa";
import { IoLogoYoutube, IoMdCall } from "react-icons/io";
import { TiLocation } from "react-icons/ti";
import { BsTwitterX } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon
import logo from "../assets/logo.jpg";

export const Footer = () => {
  const navigate = useNavigate();

  const backToHomepage = () => {
    navigate("/");
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="relative text-white bg-primary font-text">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-evenly gap-y-8 px-6 py-6 md:px-8 md:py-6 lg:px-0 lg:py-10 lg:pl-20">
        <div className="flex flex-col">
        <Link to='/' onClick={() => window.scrollTo(0, 0)}>
  <img src="/Himadri-08.png" alt="Home Logo" className="logo -ml-8 md:-ml-12  transform transition duration-300 hover:scale-110" />
</Link>

          <p className="text-sm md:text-base text-justify font-content">
            We are a team of health and personal care industry specialists
            committed to delivering the best knowledge and skills on reflexology.
          </p>
          <div className="flex items-center space-x-4 text-xl py-2">
          <Link to="https://www.facebook.com/HimadriAyurvedicHospital/" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-primary bg-white  transform transition duration-300 hover:scale-110 rounded-full size-7 text-xl p-1" />
          </Link>
          <Link to="https://www.youtube.com/@HimadriAyurvedicHospital" target="_blank" rel="noopener noreferrer">
            <IoLogoYoutube className="text-primary bg-white rounded-full size-7 text-xl p-1  transform transition duration-300 hover:scale-110" />
          </Link>
          <Link to="https://www.instagram.com/himadriayurvedichospital/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-primary bg-white rounded-full size-7 text-xl p-1  transform transition duration-300 hover:scale-110" />
          </Link>
          </div>
        </div>

        <div className="flex flex-col space-y-4 md:ml-20">
          <h3 className="text-2xl font-semibold font-subtitle">Quick Links</h3>
          <ul className="text-sm md:text-base grid grid-cols-2 gap-2 font-subtitle">
            {[
              { to: "/", text: "Home" },
              { to: "/aboutus", text: "About Us" },
              { to: "/treatment", text: "Treatments" },
              { to: "/therapies", text: "Therapies" },
              { to: "/testimonials", text: "Testimonials" },
              { to: "/event", text: "Events" },
              { to: "/career", text: "Careers" },
              { to: "/contact", text: "Contact Us" },
            ].map(({ to, text }) => (
              <li key={to}>
                <Link
                  to={to}
                  className="flex items-center hover:text-color hover:text-black transition duration-300"
                  onClick={scrollToTop}
                >
                  <FaAngleRight className="mr-2 text-sm text-color" />
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        

        <div className="flex flex-col md:ml-16 space-y-4">
          <h3 className="text-2xl font-semibold font-subtitle">Contact Information</h3>
          <div className="space-y-3 text-sm font-subtitle ">
          <div className="flex text-sm items-center md:items-start">
  <TiLocation className="text-4xl md:text-5xl -mt-3 text-color mr-2" />
  <a
    href="https://www.google.com/maps/place/Himadri+Ayurvedic+Hospital/@8.25641,77.306449,14z/data=!4m6!3m5!1s0x3b04f8c1debf1d79:0x386b832dcfcd0706!8m2!3d8.2564099!4d77.3064491!16s%2Fg%2F11rctm0cj?hl=en&entry=ttu"
    target="_blank"
    rel="noopener noreferrer"
    className="text-sm md:text-base text-white hover:text-black "
  >
    Paraicode, Mulagumoodu Post, Kanyakumari District - 629167
  </a>
</div>

            <div className="flex hover:text-black">
              <IoMdCall className="text-xl text-color mr-2" />
              <a href="tel:+919443432873" className="text-sm md:text-base">
                +91 94434 32873
              </a>
            </div>
            <div className="flex hover:text-black">
              <FaMailBulk className="text-xl text-color mr-2" />
              <a href="mailto:info@himadriayurvedha.com" className="text-sm md:text-base">
                info@himadriayurvedha.com
              </a>
            </div>
            
          </div>
        </div>

        <div className="flex flex-col space-y-2 md:ml-16 font-subtitle ">
          <h3 className="text-2xl text-md font-semibold font-subtitle "> Working Hours</h3>
          <p className="flex items-center text-md md:text-lg ">
            Monday - Friday</p>
          <p className="flex items-center text-xs md:text-sm"> <FaClock className="mr-2"/> 08:00 AM - 08:00 PM</p>
          <p className="flex items-center text-md md:text-lg ">
          Saturday - Sunday</p>
          <p className="flex items-center text-xs md:text-sm"> <FaClock className="mr-2"/> 10:00 AM - 08:00 PM</p>
        </div>
      </div>

      <hr />

      <div className="p-3 flex justify-center items-center">
        <p className="text-md text-white">
          Powered by
          <span className="hover:text-black cursor-pointer ml-2">
            <Link
              to="https://wgsdev.whytap.tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              WHY Global Services
            </Link>
          </span>
        </p>
      </div>

      {/* WhatsApp Icon */}
      <a
        href="https://wa.me/+919443432873"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-6 hidden md:block right-6  bg-green-400 p-2 rounded-full shadow-lg text-white text-3xl hover:bg-black transition duration-300"
      >
        <FaWhatsapp />
      </a>
    </footer>
  );
};
