import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Bannermain from '../main/Mainbanner';
import { Cta } from '../main/Cta';
import OurAyurvedicApproach from '../AboutUs/OurAyurvedicApproach';
import { FaArrowAltCircleRight, FaFileAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpa, FaLeaf, FaHeartbeat, FaWeight } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";

import SanityClient from '../SanityClient';

export const Treatmentsubpage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    age: "",
    documents: null,
  });

  const [status, setStatus] = useState("");
  const [documentUploaded, setDocumentUploaded] = useState(false)
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "documents") {
      setFormData({ ...formData, [name]: files[0] });
      setDocumentUploaded(!!files[0]);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await axios.post(
        "https://allmails.whytap.tech/v1/ayurveda/send-consult-email", // Ensure the backend URL is correct
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setStatus(response.data.message); // Show success message from the backend
      toast.success("Consult form sent successfully!");
    } catch (error) {
      console.error("Error sending Consult form:", error);
      setStatus("Failed to send email. Please try again."); // Show error message
      toast.error("Failed to send consult form. Please try again.");
    }
  };

  const services = [
    {
      icon: <FaSpa size={40} className="text-primary" />,
      title: 'Panchakarma Therapy',
      content: 'A rejuvenating detox treatment to cleanse the body of toxins and restore balance.',
    },
    {
      icon: <FaLeaf size={40} className="text-secondary" />,
      title: ' Pain Management',
      content: 'Holistic pain relief through natural herbal treatments and oil therapies.',
    },
    {
      icon: <FaHeartbeat size={40} className="text-primary" />,
      title: 'Weight Management ',
      content: 'Personalized Ayurvedic programs focusing on weight loss and toxin elimination.',
    },
    {
      icon: <FaWeight size={40} className="text-secondary" />,
      title: 'Stress & Anxiety Relief',
      content: 'Ancient Ayurvedic techniques to calm the mind, reduce stress, and promote inner peace.',
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 items at a time on larger screens
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scroll
    autoplaySpeed: 3000, // Adjust the speed of auto-scroll (3000ms = 3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 items at a time on tablet screens
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 item at a time on mobile screens
          slidesToScroll: 1
        }
      }
    ]
  };
  const {treatmentsubslug} = useParams()
  const [productData,setProductData]=useState([])
  const navigate = useNavigate()
  useEffect(() => {
    const fetchTreatmentData = async () => {
      try {
        const data = await SanityClient.fetch(
          `*[_type == "treatmentsubdetails" && treatmentsubslug.current == $treatmentsubslug][0]{
           title,
           Description,
           treatmentstitle,
           types[]{name,slug},
           treatmentsubslug
          }`,
          { treatmentsubslug }
        );
        setProductData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching treatment data:", error);
      }
    };

    fetchTreatmentData();
  }, [treatmentsubslug]);
    
 //breadcrumbs
 const location = useLocation();
 const breadcrumbs = useReactRouterBreadcrumbs();
 const currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1]?.breadcrumb;
 const breadcrumbText =
   typeof currentBreadcrumb === "string"
     ? currentBreadcrumb
     : location.pathname.split("/").pop() || "Home";
 const displayBreadcrumb = breadcrumbText.includes("%20")
   ? breadcrumbText.split("%20").join(" ")
   : breadcrumbText;

  return (
    <>
      <div className="relative -mt-6 flex items-center justify-center py-6 md:py-8 lg:py-9 overflow-hidden">
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: "url('/bannerimage.webp')" }}
        ></div>

        <div className="absolute inset-0 w-full h-full bg-black opacity-50"></div>

        <div className="relative z-10 flex flex-col items-center justify-center h-full p-4 sm:p-6 md:p-8">
          <h1 className="text-xl md:text-3xl lg:text-5xl capitalize  font-title text-primaryColor font-bold mb-4 text-white text-center">
            {displayBreadcrumb}
          </h1>

          <nav className="breadcrumbs text-sm text-center text-white">
            <hr className="w-full mb-4"></hr>
            {breadcrumbs.map(({ match, breadcrumb }) => {
              const breadcrumbText =
                typeof breadcrumb === "string"
                  ? breadcrumb
                  : match.pathname.split("/").pop() || "Home";

              return (
                <span key={match.pathname} className="inline-block">
                  <Link
                    to={match.pathname}
                    className="text-primaryColor capitalize hover:text-primaryColor font-subtitle text-white transition duration-300 ease-in-out"
                  >
                    {breadcrumbText.includes("%20")
                      ? breadcrumbText.split("%20").join(" ")
                      : breadcrumbText}
                  </Link>
                  {match.pathname !== location.pathname && (
                    <span className="px-2 text-white capitalize font-subtitle">
                      {" "}
                      &gt;{" "}
                    </span>
                  )}
                </span>
              );
            })}
          </nav>
        </div>
      </div>

      <div>
        <div className="flex flex-col md:flex-row px-6 py-3 pt-6 md:px-10 md:py-8 lg:p-14 lg:py-6 ">
        
          <div className="w-full md:w-2/3  md:p-6">
          <h2 className="text-xl md:text-3xl font-bold text-primary text-left  pt-1 font-title ">
          {productData?.title}

                </h2>
                
                <p className="text-sm md:text-base font-content mt-3 text-justify ">
                {productData?.Description}
                </p>
          </div>

          <div className="w-full md:w-1/3 lg:p-4 pt-6 my-auto">
          <div className=' shadow-lg px-6 py-2 pb-4 rounded-lg bg-white '>
  <h2 className='text-xl md:text-2xl font-bold text-primary text-left font-title mb-4'>{productData?.treatmentstitle}</h2>
  <ul className='list-none space-y-2'>
  {productData?.types?.map((item, ind) => (
  <li
    key={ind}
    onClick={() => {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top smoothly
      navigate(`/treatments/${item.slug}`);
    }}
    className="flex items-center cursor-pointer text-sm sm:text-base text-center transition-all duration-300 hover:font-bold hover:text-[#1173BB]"
  >
    <FaArrowAltCircleRight className="text-secondary mr-2" />
    {item?.name}
  </li>
))}

 
        
  </ul>
</div>

          
            <div className="flex flex-col shadow-md lg:items-center justify-center my-4 h-fit">
            <form
                onSubmit={handleSubmit}
                className="bg-white text-black p-2 sm:p-4 rounded-lg shadow-lg flex-1"
              >
                <p className="text-xl md:text-2xl text-left font-bold text-primary  font-title mb-4">
                  Consult Our Specialists
                </p>
                <div className="space-y-2">
                  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Name"
                    
                      value={formData.firstName}
                      onChange={handleChange}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                  
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                    <input
                      type="number"
                      name="phoneNumber"
                      placeholder="Phone Number"
                    
                      value={formData.phoneNumber}
                     onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          handleChange(e);
                        }
                      }}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                    <input
                      type="number"
                      name="age"
                      placeholder="Age"
              
                      value={formData.age}
                      onChange={handleChange}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                  </div>
                  <div className="w-full h-12 py-5 mb-4">
                  <label
                  htmlFor="file-upload"
                  className={`w-full h-full md:h-12 flex items-center justify-start md:justify-center pl-2 font-semibold border text-black cursor-pointer rounded-md text-xs sm:text-sm ${
                    documentUploaded ? 'bg-green-100 text-green-500' : 'bg-gray-100 text-gray-700'
                  } py-4 border-gray-300 hover:bg-gray-300`}
                >
                  <FaFileAlt className="mr-2" />
                  {documentUploaded ? 'Medical Reports Attached' : 'Attach Your Medical Reports'}
                </label>
                    <input
                      id="file-upload"
                      type="file"
                      name="documents"
                      onChange={handleChange}
                      className="hidden"
                    />
                  </div>

                  <div className="pt-6 flex items-center justify-center">
                    <button
                      type="submit"
                      className="w-auto px-3  py-2 bg-primary text-white text-base sm:text-sm font-semibold rounded-lg border border-bg-blue-600 transition-transform transform hover:bg-pritext-primary hover:border-pritext-primary"
                    >
                      Submit
                    </button>
                    {status && <p className="mt-2 text-green-400">{status}</p>}
                  </div>
                </div>
              </form>
              <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
            </div>
          </div>

        </div>
        <div className='bg-gray-100'>
        {/* <OurHistory/> */}
        
        <div className="px-2 md:px-10 md:py-8 lg:p-14 lg:py-5 ">
        <h2 className="text-xl md:text-3xl font-bold text-primary pl-6 md:py-3 pt-1 font-title ">
          Our Services

                </h2>
                <Slider {...settings}>
  {services.map((service, index) => (
    <div key={index} className="p-6">
      <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center justify-center w-64 h-72"> {/* Fixed width and height */}
        <div className="mb-4 flex-shrink-0"> {/* Prevent icon from stretching */}
          {service.icon}
        </div>
        <h3 className="text-xl font-semibold text-center mb-2">{service.title}</h3>
        <p className="text-gray-600 text-center">{service.content}</p>
      </div>
    </div>
  ))}
</Slider>

    </div>
    </div>
        

        <OurAyurvedicApproach />
      </div>
      <div className='bg-gray-100'>
      <Cta />
      </div>
    </>
  );
};
