import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaRegUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import SanityClient from "../SanityClient";
import { MdCategory } from "react-icons/md";

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;  // Limit posts to 6 per page

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1200 });

    // Fetch blog data from Sanity
    const fetchData = async () => {
      try {
        const data = await SanityClient.fetch(`*[_type == "blogs"]{
          _id,
          blogdate,
          blogimage{
            asset->{
              _id,
              url
            }
          },
          authorname,
          category,
          title,
          discription,
          buttoncontent,
          fblink,
          twitterlink,
          linkedinlink,
          blogslug
        }`);
        setBlogs(data);
      } catch (error) {
        console.error("Sanity fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(blogs.length / postsPerPage);

  // Page Navigation function
  const PageNavigate = () => {
    navigate("/Blog");
  };

  return (
    <>
      <div className="px-[2%] lg:px-[8%] py-10 bg-gray-100">
        <h2
          className="text-xl md:text-4xl md:-mt-3 md:mb-6 mb-3 mt-1 font-bold text-primary text-center font-title"
          data-aos="fade-up"
        >
          Blog Articles
        </h2>
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <div className="flex flex-wrap lg:w-full">
            <div className="w-full md:w-[100%] px-2 ">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                {currentPosts.map((item, index) => (
                  <div
                    className="relative bg-white text-black transform hover:-translate-y-2 shadow-2xl transition-transform duration-300 p-[4%] rounded-2xl"
                    key={index}
                    data-aos="fade-up"
                  >
                    <Link className="cursor-pointer" to={`/blog/${item?.blogslug?.current}`}>
                      <div className="relative">
                        <img
                          src={item.blogimage?.asset?.url || "/blog.jpg"}
                          alt={item.title}
                          className="w-full h-48 md:h-56 lg:h-64 object-cover object-center rounded-xl"
                        />
                        <div className="absolute top-4 left-4 text-primary bg-white font-bold px-2 py-1 rounded">
                          {item.blogdate}
                        </div>
                      </div>
                    </Link>
                    <div className="py-2 px-2">
                      {/* <ul className="flex flex-wrap items-center text-[#2e74b5] gap-3 mb-2">
                        
                        <li className="flex items-center gap-1 text-xs md:text-sm">
                          <MdCategory className="text-green-600" />
                          <span className="text-gray-600">{item.category}</span>
                        </li>
                      </ul> */}
                      <h2 className="text-sm font-semibold md:text-lg font-title mb-2">
                        {item.title}
                      </h2>
                      <p className="truncate-two-lines md:text-base text-xs font-subtitle text-gray-600 text-justify mb-4">
                        {item.discription}
                      </p>
                      
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-center items-center mt-6">
                <button
                  className="bg-primary text-white text-sm md:text-base font-semibold py-2 px-6 rounded-lg shadow-lg hover:bg-[#92d14e] transition duration-300"
                  onClick={PageNavigate}
                >
                  View All
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Blog;
