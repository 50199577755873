import React, { useState, useEffect } from "react";
import {
  FaArrowAltCircleRight,
  FaClock,
  FaEnvelope,
  FaFileAlt,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import SanityClient from "../SanityClient";
import { Link, useLocation, useParams,  } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import Slider from "react-slick";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const Treatmentdetails = () => {
  const [showForm, setShowForm] = useState(false);
  const [productData, setProductData] = useState(null);
  const { treatmentSlug } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    age: "",
    documents: null,
  });

  const [status, setStatus] = useState("");
  const [documentUploaded, setDocumentUploaded] = useState(false)

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "documents") {
      setFormData({ ...formData, [name]: files[0] });
      setDocumentUploaded(!!files[0]);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    // if (
    //   !formData.firstName ||
    //   !formData.email ||
    //   !formData.phoneNumber ||
    //   !formData.age
    // ) {
    //   setStatus("Please fill the required fields.");
    //   return;
    // }

    // Prepare form data for submission
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await axios.post(
        "https://allmails.whytap.tech/v1/ayurveda/send-consult-email", // Ensure the backend URL is correct
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setStatus(response.data.message); // Show success message from the backend
      toast.success("Consult form sent successfully!");
    } catch (error) {
      console.error("Error sending Consult form:", error);
      setStatus("Failed to send email. Please try again."); // Show error message
      toast.error("Failed to send consult form. Please try again.");
    }
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const fetchTreatmentData = async () => {
      try {
        const data = await SanityClient.fetch(
          `*[_type == "treatmentDetails" && treatmentSlug.current == $treatmentSlug][0]{
            treatmentTitle,
            bannerImage { asset -> { url } },
            contactBackgroundImage { asset -> { url } },
            treatmentCategory,
            treatmentDescription,
            causes,
            symptoms,
            treatmentheading,
            treatmentList[]{name,slug},
            contactUsText,
            ctaButtonLabel,
            treatmentSlug,
            treatmentname,
            treatmentcontentone,
            treatmentcontenttwo
          }`,
          { treatmentSlug }
        );
        setProductData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching treatment data:", error);
      }
    };

    fetchTreatmentData();
  }, [treatmentSlug]);

  const handleAppointmentClick = () => {
    setShowForm(true);
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  // if (!productData) return <p>Loading...</p>;

  //breadcrumbs
  const location = useLocation();
  const breadcrumbs = useReactRouterBreadcrumbs();
  const currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1]?.breadcrumb;
  const breadcrumbText =
    typeof currentBreadcrumb === "string"
      ? currentBreadcrumb
      : location.pathname.split("/").pop() || "Home";
  const displayBreadcrumb = breadcrumbText.includes("%20")
    ? breadcrumbText.split("%20").join(" ")
    : breadcrumbText;

  const images = [
    { src: "/abhyanga1.png", alt: "Hospital Image 1" },
    { src: "/abhyanga2.png", alt: "Hospital Image 2" },
    { src: "/about.webp", alt: "Hospital Image 3" },
    { src: "/about2.webp", alt: "Hospital Image 4" },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  return (
    <>
      {/* <Bannermain/> */}

      <div className="relative -mt-6 flex items-center justify-center py-6 md:py-8 lg:py-9 overflow-hidden">
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: "url('/bannerimage.webp')" }}
        ></div>

        <div className="absolute inset-0 w-full h-full bg-black opacity-50"></div>

        <div className="relative z-10 flex flex-col items-center justify-center h-full p-4 sm:p-6 md:p-8">
          <h1 className="text-xl md:text-3xl lg:text-5xl capitalize  font-title text-primaryColor font-bold mb-4 text-white text-center">
            {displayBreadcrumb}
          </h1>

          <nav className="breadcrumbs text-sm text-center text-white">
            <hr className="w-full mb-4"></hr>
            {/* {breadcrumbs.map(({ match, breadcrumb }) => {
              const breadcrumbText =
                typeof breadcrumb === "string"
                  ? breadcrumb
                  : match.pathname.split("/").pop() || "Home";

              return (
                <span key={match.pathname} className="inline-block">
                  <Link
                    to={match.pathname}
                    className="text-primaryColor capitalize hover:text-primaryColor font-subtitle text-white transition duration-300 ease-in-out"
                  >
                    {breadcrumbText.includes("%20")
                      ? breadcrumbText.split("%20").join(" ")
                      : breadcrumbText}
                  </Link>
                  {match.pathname !== location.pathname && (
                    <span className="px-2 text-white capitalize font-subtitle">
                      {" "}
                      &gt;{" "}
                    </span>
                  )}
                </span>
              );
            })} */}
             <div className="flex lg:gap-2 text-white text-sm capitalize">
      <Link to="/home" className=" ">
        home
      </Link> 
      &gt; 
      <Link to="/treatments" className=" ">
        treatments
      </Link> 
      &gt; 
      {productData?.treatmentCategory && (
  <>
    <Link 
      to={`/treatment/${productData.treatmentCategory.toLowerCase().replace(/\s+/g, '-')}`} 
      className=" "
    >
      {productData.treatmentCategory}
    </Link>
    &gt; 
  </>
)}

      {productData?.treatmentTitle && (
        <Link 
          // to={`/treatments/${productData.treatmentCategory}/${productData.treatmentTitle}`} 
          className=" "
        >
          {productData.treatmentTitle}
        </Link>
      )}
    </div>
          </nav>
        </div>
      </div>

      <div className=" px-4 py-6 md:px-10 lg:px-[9%] lg:py-[3%] space-y-6">
        {/* Parent Div: Split into Two Sections */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Div: Image, Treatment, Causes, and Symptoms */}
          <div className="w-full lg:w-2/3 flex flex-col">
            {/* Treatment Image and Treatment Info */}
            <div className="flex flex-col">
              <img
                src={productData?.bannerImage?.asset?.url}
                alt="Treatment Image"
                className="w-full h-[40vh] sm:h-[50vh] md:h-[60vh] object-cover shadow-lg"
                data-aos="fade-right"
              />
              <div className="bg-gray-100 p-4" data-aos="fade-up">
                <h2 className="text-xl md:text-3xl font-bold text-primary text-left md:py-3 pt-1 font-title ">
                  {productData?.treatmentTitle}
                </h2>
                <p className="text-sm sm:text-base font-content text-justify ">
                  {productData?.treatmentDescription}
                </p>
              </div>
            </div>

            {/* Causes and Symptoms */}
            <div className="flex flex-col bg-gray-100 p-1 md:flex-row ">
              {/* Causes Section */}
              <div
                className="w-full md:w-1/2 pl-4 pb-3 md:pb-0"
                data-aos="fade-right"
              >
                <h2 className="text-xl md:text-2xl font-semibold text-primary text-left md:py-3 pb-1 font-title ">
                  Causes
                </h2>
                <ul className="space-y-4">
                  {productData?.causes?.map((cause) => (
                    <li
                      key={cause}
                      className="flex items-center text-sm sm:text-base"
                    >
                      <FaArrowAltCircleRight className="mr-2 text-secondary" />
                      {cause}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Symptoms Section */}
              <div className="w-full md:w-1/2 pl-4" data-aos="fade-left">
                <h2 className="text-xl md:text-2xl font-semibold text-primary text-left py-2 md:py-3 pt-1 font-title ">
                  Symptoms
                </h2>
                <ul className="space-y-4">
                  {productData?.symptoms?.map((symptom) => (
                    <li
                      key={symptom}
                      className="flex items-center text-sm sm:text-base"
                    >
                      <FaArrowAltCircleRight className="mr-2 text-secondary" />
                      {symptom}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* treatment title and details*/}

            <div className="bg-gray-100 ">
              <h1 className="text-xl md:text-2xl font-semibold text-primary text-left  px-6 py-4 font-title">
                {productData?.treatmentname}
              </h1>
              <p className="text-sm sm:text-base text-justify text-gray- px-6">
                {productData?.treatmentcontentone}
              </p>
              <p className="text-sm sm:text-base text-justify text-gray- px-6 py-4">
                {productData?.treatmentcontenttwo}
              </p>
            </div>
          </div>

          {/* Right Div: Other Content */}
          <div className="w-full lg:w-1/3 flex flex-col space-y-6">
            
 {/* Treatment List */}
 <div
  className="bg-white shadow-md px-6 py-2 rounded-lg transition-transform duration-300 hover:transform hover:scale-105 hover:bg-white"
  data-aos="fade-left"
>
  <h2 className="text-xl md:text-2xl font-bold text-primary text-left font-title mb-4">
    {productData?.treatmentheading}
  </h2>
  <ul className="list-none space-y-4">
    {productData?.treatmentList?.map((item) => (
      <Link 
        to={`/treatments/${item?.slug?.current}`} 
        onClick={scrollToTop}  // Scrolls to top on click
        key={item?.slug?.current}
      >
        <li
          className="flex items-center cursor-pointer text-sm sm:text-base text-center transition-all duration-300 hover:font-bold hover:text-[#1173BB]"
        >
          <FaArrowAltCircleRight className="text-secondary mr-2" />
          {item?.name}
        </li>
      </Link>
    ))}
  </ul>
</div>


            {/*  Treatment therapies contacts  */}
            <div
              className="bg-white shadow-md p-4 rounded-lg transition-transform duration-300 hover:transform hover:scale-105 hover:bg-white"
              data-aos="fade-left"
            >
              <h2 className="text-xl md:text-2xl font-bold text-primary text-left font-title mb-4">
                Speak with Our Medical Experts
              </h2>
              <ul className="list-none space-y-2">
                <li className="flex items-center text-sm sm:text-base text-center transition-all duration-300 hover:font-bold hover:text-[#1173BB]">
                  <FaPhoneAlt className="text-[#1173BB] mr-2" />
                  <a href="tel:+04651252560" className="hover:text-[#1173BB]">
                    Phone: 04651 252560
                  </a>
                </li>
                <li className="flex items-center text-sm sm:text-base text-center transition-all duration-300 hover:font-bold hover:text-[#1173BB]">
                  <FaMobileAlt className="text-[#1173BB] mr-2" />
                  <a
                    href="https://wa.me/9443432873"
                    className="hover:text-[#1173BB]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mobile: +91 9443432873
                  </a>
                </li>

                <li className="flex items-center text-sm sm:text-base text-center transition-all duration-300 hover:font-bold hover:text-[#1173BB]">
                  <FaEnvelope className="text-[#1173BB] mr-2" />
                  <a
                    href="mailto:info@himadriayurvedha.com"
                    className="hover:text-[#1173BB]"
                  >
                    info@himadriayurvedha.com
                  </a>
                </li>

                <li className="flex text-sm sm:text-base transition-all duration-300 hover:font-bold hover:text-[#1173BB]">
  <FaMapMarkerAlt className="text-[#1173BB] mr-2 mt-1" />
  <a
    href="https://www.google.com/maps/place/Himadri+Ayurvedic+Hospital/@8.25641,77.306449,14z/data=!4m6!3m5!1s0x3b04f8c1debf1d79:0x386b832dcfcd0706!8m2!3d8.2564099!4d77.3064491!16s%2Fg%2F11rctm0cj?hl=en&entry=ttu"
    target="_blank"
    rel="noopener noreferrer"
    className=""
  >
    Paraicode, Mulagumoodu Post, 
    <br /> Kanyakumari District - 629167
  </a>
</li>

                
              </ul>
            </div>

            <div className="flex flex-col shadow-md lg:items-center justify-center my-4 h-fit">
            <form
                onSubmit={handleSubmit}
                className="bg-white text-black p-2 sm:p-4 rounded-lg shadow-lg flex-1"
              >
                <p className="text-xl md:text-2xl text-left font-bold text-primary  font-title mb-4">
                  Consult Our Specialists
                </p>
                <div className="space-y-2">
                  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Name"
                    
                      value={formData.firstName}
                      onChange={handleChange}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                  
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                    <input
                      type="number"
                      name="phoneNumber"
                      placeholder="Phone Number"
                    
                      value={formData.phoneNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          handleChange(e);
                        }
                      }}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                    <input
                      type="number"
                      name="age"
                      placeholder="Age"
              
                      value={formData.age}
                      onChange={handleChange}
                      className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700 "
                    />
                  </div>
                  <div className="w-full h-12 py-5 mb-4">
                  <label
                  htmlFor="file-upload"
                  className={`w-full h-full md:h-12 flex items-center justify-start md:justify-center pl-2 font-semibold border text-black cursor-pointer rounded-md text-xs sm:text-sm ${
                    documentUploaded ? 'bg-green-100 text-green-500' : 'bg-gray-100 text-gray-700'
                  } py-4 border-gray-300 hover:bg-gray-300`}
                >
                  <FaFileAlt className="mr-2" />
                  {documentUploaded ? 'Medical Reports Attached' : 'Attach Your Medical Reports'}
                </label>
                    <input
                      id="file-upload"
                      type="file"
                      name="documents"
                      onChange={handleChange}
                      className="hidden"
                    />
                  </div>

                  <div className="pt-6 flex items-center justify-center">
                    <button
                      type="submit"
                      className="w-auto px-3  py-2 bg-primary text-white text-base sm:text-sm font-semibold rounded-lg border border-bg-blue-600 transition-transform transform hover:bg-secondary hover:border-secondary"
                    >
                      Submit
                    </button>
                    {status && <p className="mt-2 text-green-400">{status}</p>}
                  </div>
                </div>
              </form>
              <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
            </div>

            <div className=" flex flex-col shadow-md items-center justify-center h-fit">
              <Slider {...settings} className="w-full">
                {images.map((image, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-64 object-cover"
                    />
                  </div>
                ))}
              </Slider>
            </div>

           
          </div>
        </div>
        <div class="bg-white p-4 rounded-lg shadow-md border">
          <h2 class="text-2xl font-bold text-center mb-4 font-title">
            Experience Natural Healing
          </h2>
          <p class="text-center text-gray-700 font-subtitle mb-6">
            Our expert practitioners provide personalized care to help you
            achieve optimal wellness and balance.
          </p>
          <div class="flex justify-center font-content">
            <Link
              to="/contact"
              class="bg-primary text-white px-4 py-2 rounded-lg shadow hover:bg-secondary transition duration-300"
            >
              Book an Appointment
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
