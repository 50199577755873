import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaUserCircle } from "react-icons/fa";
import  {Cta}  from "../main/Cta";
import Bannermain from "../main/Mainbanner";

const testimonials1 = [
  {
    id: 1,
    name: "Lincy",
    designation: "IAS",
    icon: <FaUserCircle className="w-4 h-4" />,
    content:
      "The Panchakarma treatment at Himadri transformed my health. Not only did my digestive issues improve, but I also felt a renewed sense of energy and balance. The staffs attentiveness and the peaceful environment made my stay truly healing.",
  },
  {
    id: 2,
    name: "Shifa",
    designation: "IPS",
    icon: <FaUserCircle className="w-4 h-4" />,
    content:
      "The stress management program at Himadri Ayurvedic Hospital was life-changing. Through a combination of therapies, yoga, and dietary advice, I learned to manage my anxiety naturally. The serene setting of the hospital itself is therapeutic.",
  },
];

const testimonials2 = [
  {
    id: 3,
    name: "Ilakkiya",
    designation: "AC",
    icon: <FaUserCircle className="w-4 h-4" />,
    content:
      "I've been struggling with skin issues for years. The Ayurvedic treatments at Himadri not only cleared my skin but also boosted my confidence. The doctors took the time to understand the root cause of my problems. Highly recommended!",
  },
  {
    id: 4,
    name: "Raj",
    designation: "DC",
    icon: <FaUserCircle className="w-4 h-4" />,
    content:
      "I was amazed by how the Ayurvedic weight management program at Himadri addressed not just my physical health, but also my emotional well-being. The personalized diet plan and rejuvenating treatments helped me lose weight and gain vitality.",
  },
];

export const Testimonial = () => {
  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
    <Bannermain/>
    <section className="flex flex-col items-center p-6 lg:px-32 lg:py-10 bg-white">
      <div className="flex flex-col gap-2">
        
        <h2 className="text-xl md:text-3xl font-title font-bold text-primary text-center">
          What Our Clients Say
        </h2>
        <p className="text-base md:text-base leading-relaxed font-subtitle text-gray-600 text-center">
          We are proud to share some of the feedback from our satisfied clients.
        </p>
      </div>
      {/* Container for sliders */}
      <div className="flex flex-col md:flex-row w-full justify-center gap-5">
        {/* Combined slider for mobile view */}
        <div className="w-full md:hidden">
          <Slider {...settings} className="flex-1">
            {testimonials1.concat(testimonials2).map(({ id, name, icon, content }) => (
              <div
                key={id}
                className="flex flex-col items-center justify-center p-10 m-2 bg-white rounded-md shadow-md space-y-5"
                style={{ width: "200px", height: "200px" }}
              >
                <div>
                  <p className="text-sm leading-relaxed font-subtitle text-gray-400 mb-1 text-justify">
                    {content}
                  </p>
                </div>
                <div className="w-25 h-6 flex items-center gap-2">
                  {icon}
                  <span className="text-md md:text-md font-subtitle text-secondary">
                    {name}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Separate sliders for desktop view */}
        <div className="hidden md:block w-full md:w-1/2">
          <Slider {...settings} className="flex-1">
            {testimonials1.map(({ id, name, icon, content }) => (
              <div
                key={id}
                className="flex flex-col items-center justify-center p-10 m-2 bg-white rounded-md shadow-md space-y-5"
                style={{ width: "200px", height: "200px" }}
              >
                <div>
                  <p className="text-sm leading-relaxed font-subtitle text-gray-600 mb-1 text-justify">
                    {content}
                  </p>
                </div>
                <div className="w-25 h-6 flex items-center gap-2">
                  {icon}
                  <span className="text-md md:text-md font-subtitle text-secondary">
                    {name}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="hidden md:block w-full md:w-1/2">
          <Slider {...settings} className="flex-1">
            {testimonials2.map(({ id, name, icon, content }) => (
              <div
                key={id}
                className="flex flex-col items-center justify-center p-10 m-2 bg-white rounded-md shadow-md space-y-5"
                style={{ width: "200px", height: "200px" }}
              >
                <div>
                  <p className="text-sm leading-relaxed font-subtitle text-gray-600 mb-1 text-justify">
                    {content}
                  </p>
                </div>
                <div className="w-25 h-6 flex items-center gap-2">
                  {icon}
                  <span className="text-md md:text-md font-subtitle text-secondary">
                    {name}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
    <Cta/>
    </>
  );
};
