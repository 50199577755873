import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="flex justify-center mt-8">
      <nav>
        <ul className="flex gap-2">
          {pages.map((page) => (
            <li key={page}>
              <button
                onClick={() => onPageChange(page)}
                className={`px-3 py-1.5 rounded-full border text-xs ${
                  page === currentPage
                    ? "bg-[#2e74b5] text-white"
                    : "bg-white text-[#2e74b5] border-[#2e74b5]"
                } hover:bg-[#1c4f7d] hover:text-white transition duration-300`}
              >
                {page}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
