import React from 'react';
import { IoMdCall } from 'react-icons/io';

const OurHistory = () => {
  return (
    <div className="flex items-center justify-between px-6 py-6 md:px-16 lg:mx-16 md:py-10 lg:px-0 lg:py-10">
      <div className="flex flex-col lg:flex-row items-center justify-center w-full">
        {/* Left Column - Text Content */}
        <div className="w-full lg:w-3/4 bg-white border border-[#2e74b5] p-6 lg:p-10">
        <div className='lg:w-[85%]'>
          <p className="lg:text-2xl mb-4 lg:mb-6  font-title text-secondary text-xl">
            Founder of Himadri Ayurvedic Hospital
          </p>

          <div className="flex flex-col lg:flex-row">
            <div className="">
              <h2
                className="lg:text-3xl font-bold mb-4 lg:mb-6  md:text-4xl font-title text-primary text-xl"
                data-aos="fade-right"
              >
                A Legacy of Healing and Wellness
              </h2>
            </div>
            <div className="flex lg:ml-3 mb-4 md:mb-0 lg:mt-1 gap-1">
              {/* Phone Icon in Round Circle */}
              <div className="bg-[#92d14e] md:w-8 md:h-8 w-6 h-6 rounded-full flex items-center justify-center">
                <IoMdCall className="text-white text-xl" />
              </div>
              <div>
                <a
                  href="tel:+919443432873"
                  className="text-sm md:text-sm font-semibold text-gray-800"
                >
                  +91 94434 32873
                </a>
              </div>
            </div>
          </div>

          <p className="text-gray-700 italic mb-4 pl-2 border-l-4 lg:border-l-6 border-[#92d14e] text-justify">
            Dr. Ratheesh founded Himadri Ayurvedic Hospital with the mission to
            bring the ancient wisdom of Ayurveda to the forefront of modern
            healthcare. He spent years researching and developing treatment
            methodologies that blend traditional Ayurvedic principles with
            contemporary medicine. Since its inception, the hospital has been at
            the cutting edge of Ayurvedic research and has pioneered treatments
            for chronic diseases, lifestyle disorders, and mental well-being.
            Today, Himadri Ayurvedic Hospital is recognized as a trusted name in
            Ayurveda, continuing to uphold Dr. Ratheesh’s vision of providing
            natural, sustainable healthcare.
          </p>
          <p
            className="text-justify md:text-base text-sm leading-relaxed font-subtitle text-gray-800"
            data-aos="fade-left"
          >
            Driven by a deep-rooted passion for natural healing, Himadri
            Ayurvedic Hospital stands as a beacon of holistic wellness. The
            hospital’s philosophy is grounded in the timeless principles of
            Ayurveda, offering treatments that not only address physical
            ailments but also restore balance to the mind and spirit.
          </p>
          </div>
        </div>

        {/* Right Column - Image */}
        <div
  className="w-full lg:w-1/3 relative lg:-ml-32 pt-6 lg:pt-0 flex items-start justify-end"
  data-aos="fade-up"
>
  <img
    src="/banner.jpeg"
    alt="Main"
    className="md:h-[400px] w-[400px] object-cover shadow-lg border-t-4 border-r-4 border-b-4 border-[#92d14e]"
  />
</div>


      </div>
    </div>
  );
};

export default OurHistory;
