import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { TfiClose } from 'react-icons/tfi'; // Ensure correct import
import { FaBriefcase, FaCalendarAlt } from 'react-icons/fa';
import { SiAegisauthenticator } from 'react-icons/si';
import { MdDiversity2 } from 'react-icons/md';
import { FaLocationCrosshairs } from 'react-icons/fa6';

// Optional: Bind modal to your app element for accessibility
Modal.setAppElement('#root');

const Jobdetails = ({ jobs }) => {
  const { id } = useParams();
  const job = jobs.find((job) => job.id === parseInt(id));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    resume: null,
  });

  if (!job) {
    return <div>Job not found.</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Form Data:', formData);
    // Close the modal after submission
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="p-6 max-w-4xl mx-auto font-title bg-white shadow-md m-6">
    <h2 className="text-2xl text-primary font-bold mb-4">{job.title}</h2>
    <div className="mb-4 flex items-center">
    <SiAegisauthenticator className="mr-2 text-primary" />
      <span className="font-bold font-subtitle">Category:</span> {job.category}
    </div>
    <div className="mb-4 flex items-center">
    <MdDiversity2 className="mr-2 text-primary" />
      <span className="font-bold font-subtitle">Type:</span> {job.type}
    </div>
    <div className="mb-4 flex items-center">
    <FaLocationCrosshairs className="mr-2 text-primary" />
      <span className="font-bold font-subtitle">Location:</span> {job.location}
    </div>
    <div className="mb-4 flex items-center">
      <FaBriefcase className="mr-2 text-primary" />
      <span className="font-bold font-subtitle">Experience:</span> {job.experience}
    </div>
    <div className="mb-4 flex items-center">
      <FaCalendarAlt className="mr-2 text-primary" />
      <span className="font-bold font-subtitle">Application Deadline:</span> {job.deadline}
    </div>
    <div className="flex mt-6">
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-[#AED232] text-white font-bold py-2 px-4 rounded"
      >
        Apply Now
      </button>
    </div>
  </div>
  

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="fixed inset-0 flex items-end justify-center p-4 bg-gray-800 bg-opacity-50"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
        contentLabel="Application Form"
      >
        <div className="relative bg-white p-6 rounded shadow-lg w-full max-w-md">
          <TfiClose
            className="absolute top-4 right-4 h-4 w-4 text-green-500 cursor-pointer"
            onClick={() => setIsModalOpen(false)} // Close modal on click
          />
          <h2 className="text-xl font-bold mb-4 font-title">Application Form</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="mobile">
                Mobile
              </label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="resume">
                Resume (CV)
              </label>
              <input
                type="file"
                id="resume"
                name="resume"
                onChange={handleFileChange}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="flex justify-end font-title">
              <button
                type="submit"
                className="bg-[rgb(17,115,187)] text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Jobdetails;
