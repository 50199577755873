import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img2 from "../assets/IMG_1220.png"
import img3 from "../assets/IMG_1228.png"
import img4 from "../assets/WhatsApp Image 2024-09-11 at 1.07.21 PM.png"
import img5 from "../assets/WhatsApp Image 2024-09-11 at 1.07.35 PM.png"




const Infrastructure = () => {
  const images = [
 
    { src: img2, alt: 'Infrastructure 2' },
    { src: img3, alt: 'Infrastructure 3' },
    { src: img4, alt: 'Infrastructure 4' },
    { src: img5, alt: 'Infrastructure 5' },
    
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='px-[7%] py-10  bg-gray-100'>
      <h2 className='text-xl md:text-4xl font-bold text-primary text-center md:py-3 py-1 font-title mb-6'>Our Infrastructure</h2>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2 ">
            <img
              src={image.src}
              alt={image.alt}
              className=" object-cover"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Infrastructure;
