import React, { useEffect, useState } from "react";
import { FaArrowAltCircleRight,} from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import sanityClient from "../SanityClient";
import BlockContent from "@sanity/block-content-to-react";
import { Cta } from "../main/Cta";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import AOS from "aos";
import "aos/dist/aos.css";


// Accordion Item Component
const AccordionItem = ({ title, content, isOpen, onToggle }) => {
  const location = useLocation();

  // Scroll to top on page load
  useEffect(() => {
    // scrollToTop();
  }, [location]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <li className={`accordion block ${isOpen ? "active-block" : ""} mb-5`}>
      <div
        className={`acc-btn flex justify-between items-center md:text-base  text-sm p-6 ${
          isOpen ? "bg-primary text-white" : "bg-gray-100"
        } rounded-lg cursor-pointer`}
        onClick={onToggle}
      >
        <h3 className="text-md  font-subtitle">{title}</h3>
        <div className="icon-outer">
          <span className="text-xl font-bold">{isOpen ? "−" : "+"}</span>
        </div>
      </div>
      <div
        className={`acc-content p-5 ${
          isOpen ? "bg-orange-50" : "bg-gray-50"
        } rounded-lg ${isOpen ? "block" : "hidden"}`}
      >
        <p>{content}</p>
      </div>
    </li>
  );
};

const Abhyanga = () => {
  const serializers = {
    types: {
      block: (props) => {
        return (
          <p className="font-content text-gray-800 leading-6 text-justify md:text-base text-sm">
            {props.children}
          </p>
        );
      },
      nextBlog: (props) => {
        return (
          <p className="text-justify md:text-base text-sm font-content text-gray-500 leading-6">
            {props.children}
          </p>
        );
      },
    },
  };

  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [productdata, setProductData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "abhyanga"]{ title, slug }`)
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "abhyanga" && slug.current == ${JSON.stringify(slug)}]{
          title,
          image { asset -> { url } },
          benefitImage { asset -> { url } },
          benefitTitle,
          description,
          benefitDescription,
          usestitle,
          usespoint1,
          usespoint2,
          usespoint3,
          usespoint4,
          usespoint5,
          contraindicationstitle,
          contraindicationsdescriptions,
          accordion,
          contactPhone,
          contactEmail,
          brochureLink,
          slug
        }`
      )
      .then((data) => {
        setProductData(data[0]);
      });
  }, [slug]);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Breadcrumbs
  const location = useLocation();
  const breadcrumbs = useReactRouterBreadcrumbs();
  const currentBreadcrumb = breadcrumbs[breadcrumbs.length - 1]?.breadcrumb;
  const breadcrumbText =
    typeof currentBreadcrumb === "string"
      ? currentBreadcrumb
      : location.pathname.split("/").pop() || "Home";
  const displayBreadcrumb = breadcrumbText.includes("%20")
    ? breadcrumbText.split("%20").join(" ")
    : breadcrumbText;
  // Function to scroll to the top of the banner section
  const scrollToTop = () => {
    const bannerSection = document.getElementById("banner-section");
    if (bannerSection) {
      bannerSection.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <>
    <div className="relative -mt-6 flex items-center justify-center py-6 md:py-8 lg:py-9 overflow-hidden">
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: "url('/bannerimage.webp')" }}
        ></div>

        <div className="absolute inset-0 w-full h-full bg-black opacity-50"></div>

        <div className="relative z-10 flex flex-col items-center justify-center h-full p-4 sm:p-6 md:p-8">
          <h1 className=" capitalize text-xl md:text-2xl lg:text-5xl font-title  font-bold mb-4 text-white text-center">
            {displayBreadcrumb}
          </h1>

          <nav className="breadcrumbs text-sm text-center capitalize text-white">
            <hr className="w-full mb-4"></hr>
            {breadcrumbs.map(({ match, breadcrumb }) => {
              const breadcrumbText =
                typeof breadcrumb === "string"
                  ? breadcrumb
                  : match.pathname.split("/").pop() || "Home";

              return (
                <span key={match.pathname} className="inline-block">
                  <Link
                    to={match.pathname}
                    className=" hover:text-primary capitalize font-subtitle text-white transition duration-300 ease-in-out"
                  >
                    {breadcrumbText.includes("%20")
                      ? breadcrumbText.split("%20").join(" ")
                      : breadcrumbText}
                  </Link>
                  {match.pathname !== location.pathname && (
                    <span className="px-2 text-white font-subtitle">
                      {" "}
                      &gt;{" "}
                    </span>
                  )}
                </span>
              );
            })}
          </nav>
        </div>
      </div> 
      
      <div className="flex flex-col w-full  justify-center  gap-8 p-8 lg:p-14 lg:flex-row">
        {/* Sidebar Section - 25% */}
        <div
          className="w-full lg:w-[30%] p-4 lg:p-6 h-fit mb-6 md:mb-0 bg-white shadow-md hidden lg:block "
          data-aos="fade-left"
        >
          <div>
            <ul className="space-y-4">
              {data.map((title) => {
                const isActive = title?.slug?.current === slug;

                return (
                  <Link
                      to={`/therapies/${title?.slug?.current}`}
                      className={`w-full px-1 ${
                        isActive
                          ? ""
                          : "text-gray-800 md:text-base text-sm font-subtitle"
                      }`}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                  <li
                    key={title?.slug?.current}
                    className={`text-lg rounded-lg w-full p-3 shadow-lg ${
                      isActive
                        ? "bg-primary text-white"
                        : "bg-white text-gray-800"
                    }`}
                  >
                    
                      {title?.title}
                   
                  </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Content Section - 55% */}
        <div className="w-full lg:w-[62%] " data-aos="fade-right">
          {/* Image Box */}
          <div className="service-details-img-box mb-6">
            <img
              width=""
              height=""
              src={productdata?.image?.asset?.url}
              alt="Service Details"
              className="w-full h-80 image object-cover rounded-lg shadow-lg"
            />
          </div>

          {/* Service Title */}
          <div className="service-details-text-1 ">
            <h2 className="text-xl md:text-3xl font-semibold text-justify text-primary font-title py-1">
              {productdata?.title}
            </h2>
          </div>

          {/* Description */}
          <div className="service-details-text-2 text-justify py-3">
            <BlockContent
              blocks={productdata?.description}
              projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
              dataset={process.env.REACT_APP_SANITY_DATASET}
              serializers={serializers}
            />
          </div>

          {/* Benefits and Contact Section */}
          <div className="benefit-container">
            <div className="flex flex-col lg:flex-row">
              <div className="benefit-text lg:w-2/3 md:pr-4 ">
                <h2 className="text-xl md:text-2xl font-semibold text-primary py-3">
                  {productdata?.benefitTitle}
                </h2>
                <BlockContent
                  blocks={productdata?.benefitDescription}
                  projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                  dataset={process.env.REACT_APP_SANITY_DATASET}
                  serializers={serializers}
                />
              </div>
              <div className="benefit-img lg:w-1/2 pt-4 lg:px-4 w-full">
                <img
                  width=""
                  height=""
                  src={productdata?.benefitImage?.asset?.url}
                  alt="Benefit Image"
                  className="lg:w-72 lg:h-44 object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>

          {/* Usera */}
          <div className="">
  {/* Title */}
  <h2 className="text-xl md:text-2xl w-full font-title font-semibold text-primary py-3 lg:pb-3"> {productdata?.usestitle}</h2>
  
  
  
  {/* Points List */}
  <ul className="space-y-2 text-sm  md:text-base font-content">
    {/* Point 1 */}
    <li className="flex items-start  space-x-3">
    <FaArrowAltCircleRight className="flex-shrink-primary text-secondary text-lg"/> {/* Replace with icon */}
      <div>
        <p className=""> {productdata?.usespoint1}</p>
      </div>
    </li>
    
    {/* Point 2 */}
    <li className="flex items-start space-x-3">
    <FaArrowAltCircleRight className="flex-shrink-primary text-secondary  text-lg"/> {/* Replace with icon */}
      <div>
        <p className="">{productdata?.usespoint2}</p>
      </div>
    </li>
    
    {/* Point 3 */}
    <li className="flex items-start space-x-3">
    <FaArrowAltCircleRight className="flex-shrink-primary text-secondary text-lg"/> {/* Replace with icon */}
      <div>
       
        <p className="">{productdata?.usespoint3}</p>
      </div>
    </li>
    
    {/* Point 4 */}
    <li className="flex items-start space-x-3">
    <FaArrowAltCircleRight className="flex-shrink-primary text-secondary text-lg"/> {/* Replace with icon */}
      <div>
      
        <p className="">{productdata?.usespoint4}</p>
      </div>
    </li>
    
    {/* Point 5 */}
    <li className="flex items-start space-x-3">
      <FaArrowAltCircleRight className="flex-shrink-primary text-secondary text-lg"/> {/* Replace with icon */}
      <div>
        
        <p className="">{productdata?.usespoint5} </p>
      </div>
    </li>
  </ul>

</div>

          {/* Contraindications */}
          <div className="pt-4">
  {/* Title */}
  <h2 className="text-xl md:text-2xl font-title font-semibold text-primary pb-3">{productdata?.contraindicationstitle}</h2>
  
  {/* Description */}
  <p className="mb-6 font-content text-sm md:text-base  text-justify">
  {productdata?.contraindicationsdescriptions}
  </p>
  
 
  
</div>
{/* AyurVAID’s Approach  */}
<div className="">
  {/* Title */}
  <h2 className="text-xl md:text-2xl font-semibold font-title text-primary py-3">Himadri AyurVAID’s Approach </h2>
  
  {/* Description */}
  <p className="mb-6 text-justify text-sm md:text-base  font-content">
  At Himadri AyurVAID, we are dedicated to providing precision classical 
  Ayurveda treatment protocols that adhere to the core principles of 
  traditional Ayurvedic medicine. Our focus is on addressing the root 
  causes of various health conditions through personalized and effective 
  therapies. One of our key treatments, Abhyangam, is utilized to manage 
  a wide array of disorders, including musculoskeletal conditions such as 
  Arthritis, Lumbar Spondylitis, and Cervical Spondylitis, as well as sports 
  injuries and neurodegenerative conditions like Parkinsonism and Motor Neuron Disease.
  </p>
  
  
</div>


          {/* Accordion Section */}
          <div className="accordion-container ">
  <h2 className="text-xl md:text-2xl font-semibold font-title text-primary pb-4">FAQ</h2>

            <ul className="space-y-4">
              {productdata?.accordion?.map((item, index) => (
                <AccordionItem
                  key={index}
                  title={item.question}
                  content={item.answer}
                  isOpen={openIndex === index}
                  onToggle={() => handleToggle(index)}
                  titleClassName="text-lg font-semibold" // Customize the title size here
                />
              ))}
            </ul>
          </div>
          <div>
            </div>
        </div>
      </div>
      

      <div className='bg-gray-100'><Cta/></div>

    </>
  );
};

export default Abhyanga;
