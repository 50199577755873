import React from 'react'
import { OurTreatment } from './OurTreatment '
import  {Testimonial}  from './Testimonial'

import { Topbar } from '../main/Topbar'
import { Navbar } from '../main/Navbar'
import { Banner } from './Banner'
import Ayurvedic from './Ayurvedic'
import Blog from './Blog'
import  {Cta}  from '../main/Cta'
import { About } from './About'

export const Home = () => {
  return (
    <div>
        
        <Banner/>
        <About/>
        <OurTreatment/>
        <Ayurvedic/>
        <Blog/>
        <Testimonial/>
        <div className='bg-gray-100'><Cta/></div>
        
    </div>
  )
}
