import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import { GiTeslaCoil } from "react-icons/gi";
import Bannermain from "../main/Mainbanner";
import SanityClient from "../SanityClient";
import { Link } from "react-router-dom";
import { Cta } from "../main/Cta";

const AyurvedicMain = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1000 });

    // Fetch data from Sanity
    SanityClient.fetch(
      `*[_type == "abhyanga"]{
        title,
        description,
        image{
          asset->{
            _id,
            url
          }
        },
        slug
      }`
    )
      .then((data) => setServices(data))
      .catch((error) => {
        console.error(error);
        setError("Failed to load services. Please try again later.");
      });
  }, []);

  return (
    <>
      <Bannermain />
      <div className="grid grid-cols-1 md:gap-5 py-6 md:py-8 px-[7%] md:px-12">
        <h2
          className="text-xl md:text-4xl md:-mt-3 mt-1 font-bold text-primary text-center font-title"
          data-aos="fade-up md:fade-up" // Applies fade-up animation only on md and larger
        >
          Holistic Healing for Your True Self
        </h2>
        <div className="font-subtitle">
          <p
            className="flex justify-center md:mt-0 mt-3 text-justify md:text-center text-gray-800 text-sm md:text-base"
            data-aos="fade-up md:fade-up" // Applies fade-up animation only on md and larger
          >
            Ayurvedic medicine offers a range of services to promote health and
            treat various health conditions. Here are some common services
            provided by Ayurvedic practitioners:
          </p>
        </div>
        {error ? (
          <div className="text-center text-red-500 mt-4">{error}</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 py-[10px] md:px-[7%] gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white p-5 w-full sm:w-auto border border-l-4 border-l-green-700 hover:shadow-lg gap-2"
                data-aos="md:fade-right" // Applies fade-right animation only on md and larger
              >
                <GiTeslaCoil className="text-5xl text-white bg-[#1173BB] p-2 rounded-full" />
                <h1 className="text-lg md:text-xl font-semibold py-2 font-title">
                  {service.title}
                </h1>
                <p className="text-gray-800 text-sm md:text-base font-subtitle truncate-lines-3">
                  {service.description && service.description[0]?.children?.[0]?.text
                    ? service.description[0].children[0].text
                    : "Description not available"}
                </p>
                <Link to={`/therapies/${service?.slug?.current}`}>
  <button
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    className="flex gap-2 items-center text-sm font-semibold text-green-700 mt-4 hover:translate-x-2 transition-transform duration-300"
  >
    <BsArrowRight /> <span>READ MORE</span>
  </button>
</Link>

              </div>
            ))}
          </div>
        )}
      </div>
      <div className="bg-gray-100">
        <Cta />
      </div>
    </>
  );
};

export default AyurvedicMain;
