import React from 'react';
import { FaPhone } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IoMdCall } from 'react-icons/io';

export const About = () => {
  const navigate = useNavigate();
  const PageNavigate =()=>{
    navigate("/aboutus")
  }
  return (
    <div className="px-[7%] py-7 md:px-[10%] mb-10">
      {/* <Fade left> */}
        <div className="flex flex-col gap-6 md:flex-row items-center md:items-start space-y-6 md:space-y-0 md:space-x-4 lg:space-x-3">
          {/* Main Image and Experience Box */}
          <div className="w-full md:w-1/2 mb-6  md:mb-0 relative grid place-content-center " data-aos="fade-up"
          >
            {/* Main Image */}
            <img
              src="\aboutimage.webp"
              alt="Main"
              className="  md:h-[400px] object-cover shadow-lg"
            />
            {/* Bottom Left Image and Experience Text Overlay */}
            <div className="relative">
              <img
                src="/cta image.jpg"
                alt="Secondary"
                className="absolute bottom-[-50px] left-[-60px] w-2.5/3 h-[150px] sm:h-[200px]  object-cover rounded-lg border-4 border-white  lg:block hidden shadow-lg "
              />
              
            </div>
          </div>

          {/* Text Content */}
          <div className="w-full md:w-1/2 text-center md:py-[4%] md:text-left   " >
            <h2 className="lg:text-3xl font-bold mb-4 lg:mb-6 text-justify md:text-4xl  font-title text-primary text-xl "  data-aos="fade-right"
            >
              Totally Effective <br /> Approach for a Better Life
            </h2>
            <p className="text-gray-700 italic flex mb-4 pl-2 border-l-4 lg:border-l-8 border-[#92d14e] items-center text-justify ">
            "Himadri Ayurvedic Hospital offers personalized Ayurvedic care, combining ancient wisdom with modern treatments for holistic health and well-being, ensuring a balanced mind, body, and spirit."</p>
            <p className="text-justify md:text-base text-sm leading-relaxed font-subtitle text-gray-800 " data-aos="fade-left">
              Welcome to Himadri Ayurvedic Hospital, a sanctuary for holistic health and well-being. Situated in the serene locale of Paraicode, Mulagumoodu Post, Kanyakumari District, our hospital is dedicated to providing authentic Ayurvedic treatments rooted in the timeless wisdom of this ancient practice.
            </p>
            

            {/* Button and Call Us Section */}
            <div className="flex flex-row gap-5 items-center mt-4">
              <button className="bg-primary text-white text-xs sm:text-base font-semibold md:py-3 md:px-6 px-3 py-3 rounded-lg shadow-lg hover:bg-[#2e74b5] transition duration-300" onClick={PageNavigate}>
                About Us
              </button>
              <div className="flex gap-1  items-center ">
                {/* Phone Icon in Round Circle */}
                <div className="bg-[#92d14e] md:w-8 md:h-8  w-6 h-6 rounded-full flex items-center justify-center ">
                  <IoMdCall className="text-white text-xl" />
                </div>
                <div>
                  <a
                    href="tel:+919443432873"
                    className="text-sm md:text-sm font-semibold text-gray-800"
                  >
                    +91 94434 32873
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </Fade> */}
    </div>
  );
};
