import React from 'react';
import Bannermain from '../main/Mainbanner';
import OurHistory from './OurHistory';
import OurMissionVision from './OurMissionVision';
import OurDoctors from './OurDoctors';
import Infrastructure from './Infrastructure';
import { Qualitysafety } from './Quality&safety';
import  {Cta}  from '../main/Cta';
import { About } from './About';

const Aboutus = () => {
 

  return (
    <div>
      
      <Bannermain />
      <OurHistory/>
      <About/>
      <OurMissionVision/>
      
      
      {/* <OurDoctors/> */}
      {/* <Qualitysafety/> */}
      <Infrastructure/>
      <div className='bg-white'><Cta/></div>
      

    

      
    </div>
  );
}

export default Aboutus;
