
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Home } from './Home/Home';
import { Topbar } from './main/Topbar';
import Contact from './Contact/Contact';
import { Footer } from './main/Footer';
import Navbar from './main/Navbar';
import { Treatmentdetails } from './Treatments/Treatmentdetails';
import Abhyanga from './ayurvedicTherapies/Abhyanga';
import Blog from './blog/Blog';
import Blogdetails from './blog/Blogdetails';
import EventDetails from './Event/EventDetails';
import Aboutus from './AboutUs/Aboutus';
import { TreatmentPage } from './Treatments/TreatmentPage';
import { Testimonial } from './Testimonials/Testimonials';
import Career from './career/Career';
import AyurvedicMain from './ayurvedicTherapies/AyurvedicMain';
import Jobdetails from './career/Jobdetails';
import { SiTestin } from 'react-icons/si';
import Event from "./Event/Event"
import { Treatmentsubpage } from './Treatments/Treatmentsubpage';

function App() {
  const [jobs, setJobs] = useState([
    {
      id: 1,
      title: 'Ayurvedic Doctor',
      category: 'Healthcare',
      type: 'Full-Time',
      location: 'Haridwar',
      deadline: '2024-09-30',
      experience: '5+ years',
    },
    {
      id: 2,
      title: 'Pharmacist',
      category: 'Healthcare',
      type: 'Part-Time',
      location: 'Rishikesh',
      deadline: '2024-10-15',
      experience: '2+ years',
    },
    {
      id: 3,
      title: 'Yoga Instructor',
      category: 'Healthcare',
      type: 'Contract',
      location: 'Dehradun',
      deadline: '2024-08-31',
      experience: '3+ years',
    },
    {
      id: 4,
      title: 'Nursing Staff',
      category: 'Healthcare',
      type: 'Full-Time',
      location: 'Haridwar',
      deadline: '2024-09-15',
      experience: '1+ year',
    },
    {
      id: 5,
      title: 'Administrative Assistant',
      category: 'Administration',
      type: 'Full-Time',
      location: 'Haridwar',
      deadline: '2024-09-25',
      experience: '3+ years',
    },
  ]);

  return (
    <div className=''>
      <Topbar />
      <Navbar />
      <div className=''>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Treatmentdetails' element={<Treatmentdetails />} />
          <Route path='/treatments/:treatmentSlug' element={<Treatmentdetails />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/abhyanga' element={<Abhyanga />} />
          <Route path='/therapies/:slug' element={<Abhyanga />} />
          <Route path='/testimonials' element={<Testimonial/>}/>
          <Route path='/blog' element={<Blog />} />
          <Route path='/event' element={<Event/>}/>
          <Route path='/aboutus' element={<Aboutus />} />
          <Route path='/blogdetail' element={<Blogdetails />} />
          <Route path='/blog/:blogslug' element={<Blogdetails />} />
          <Route path='/event/:eventslug' element={<EventDetails />} />

          <Route path='/treatment' element={<TreatmentPage />} />
          <Route path='/eventdetails' element={<EventDetails />} />
          <Route path='/career' element={<Career jobs={jobs} />} />
          <Route path='/therapies' element={<AyurvedicMain />} />
      
          <Route path='/treatment/:treatmentsubslug' element={<Treatmentsubpage/>}/>
        
          <Route path='/jobdetails/:id' element={<Jobdetails jobs={jobs} />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
