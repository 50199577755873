import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { FaFileAlt } from 'react-icons/fa';

export const Cta = () => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    phoneNumber: '',
    age: '',
    problem: '',
    documents: null,
    message: '',
  });

  const [documentUploaded, setDocumentUploaded] = useState(false); // State to track document upload
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    // Initialize AOS with screen width check
    AOS.init({
      disable: function () {
        // Disable AOS on mobile devices (screen width < 768px)
        return window.innerWidth < 768;
      }
    });

    // Update AOS on window resize
    window.addEventListener('resize', () => AOS.refresh());
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'documents') {
      setFormData({ ...formData, [name]: files[0] });
      setDocumentUploaded(!!files[0]); // Update state to true if file is uploaded
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateMobileNumber = (number) => {
    const regex = /^\d{10}$/;
    return regex.test(number);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateMobileNumber(formData.phoneNumber) > 10) {
      setError("Please enter a valid 10-digit mobile number.");
    } else {
      setError("");
    }
    // Basic validation
    if (!formData.firstName || !formData.email || !formData.phoneNumber || !formData.age) {
      setStatus('Please fill the required fields.');
      return;
    }

    // Prepare form data for submission
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await axios.post("https://allmails.whytap.tech/v1/ayurveda/send-cta-email", formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setStatus(response.data.message);
      toast.success("Appointment form sent successfully!");
    } catch (error) {
      console.error("Error sending Appointment form:", error);
      setStatus("Failed to send email. Please try again."); // Show error message
      toast.error("Failed to send Appointment form. Please try again.");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center sm:flex-row py-5 md:px-10 lg:px-28 md:gap-6">
      {/* Image */}
      <div 
        className="w-full lg:w-1/2 px-4 md:px-0 md:py-5 flex items-stretch"
        data-aos="fade-right"
      >
        <img
          src="/cta image.jpg"
          alt="Main"
          className="w-full h-full object-cover rounded-lg shadow-md"
        />
      </div>

      {/* Form */}
      <div 
        className="w-full lg:w-1/2 px-4 md:px-2 py-5 flex items-stretch"
        data-aos="fade-left"
      >
        <form onSubmit={handleSubmit} className="bg-white text-black p-4 sm:p-8 rounded-lg shadow-lg flex-1">
          <p className="md:text-4xl text-xl font-title mb-4">Get an Appointment</p>
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
              <input
                type="text"
                name="firstName"
                placeholder="Name"
                required
                value={formData.firstName}
                onChange={handleChange}
                className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                value={formData.email}
                onChange={handleChange}
                className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700"
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
              <input
                type="number"
                name="phoneNumber"
                required
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    handleChange(e);
                  }
                }}
                className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700"
              />
              <input
                type="number"
                name="age"
                placeholder="Age"
                required
                value={formData.age}
                onChange={handleChange}
                className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700"
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:space-x-20 space-y-4 sm:space-y-0">
              <input
                type="text"
                name="problem"
                placeholder="Problems"
                value={formData.problem}
                onChange={handleChange}
                className="w-full sm:w-1/2 h-12 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none placeholder:font-subtitle placeholder-gray-700"
              />
              <div className="w-full h-14 py-5 mb-4">
                <label
                  htmlFor="file-upload"
                  className={`w-full h-full md:h-12 flex items-center justify-start md:justify-center pl-2 font-semibold border text-black cursor-pointer rounded-md text-xs sm:text-sm ${
                    documentUploaded ? 'bg-green-100 text-green-500' : 'bg-gray-100 text-gray-700'
                  } py-4 border-gray-300 hover:bg-gray-300`}
                >
                  <FaFileAlt className="mr-2" />
                  {documentUploaded ? 'Medical Reports Attached' : 'Attach Your Medical Reports'}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="documents"
                  onChange={handleChange}
                  className="hidden"
                />
              </div>
            </div>
            <textarea
              name="message"
              placeholder="Problem description"
              value={formData.message}
              onChange={handleChange}
              className="w-full h-24 sm:h-32 px-4 py-2 bg-white border-b border-gray-300 text-xs sm:text-sm focus:outline-none mt-4 placeholder:font-subtitle placeholder-gray-700"
            />
            <button
              type="submit"
              className="w-auto px-3 py-2 bg-primary text-white text-base sm:text-sm font-semibold rounded-lg border border-bg-blue-600 transition-transform transform hover:bg-secondary hover:border-sebg-secondary mt-4"
            >
              Book an Appointment
            </button>
            {status && <p className="mt-2 text-green-400">{status}</p>}
          </div>
        </form>
        {/* Toast Container */}
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      </div>
    </div>
  );
};
