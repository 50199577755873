import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Bannermain from '../main/Mainbanner';
import { FaMapMarkerAlt, FaBriefcase, FaCalendarAlt, FaTags } from 'react-icons/fa';
import { MdOutlineCategory } from 'react-icons/md';

const Career = ({ jobs }) => {
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of jobs to display per page

  const filteredJobs = jobs.filter((job) => {
    return (
      (category === '' || job.category === category) &&
      (type === '' || job.type === type) &&
      (location === '' || job.location === location)
    );
  });

  // Calculate paginated jobs
  const indexOfLastJob = currentPage * itemsPerPage;
  const indexOfFirstJob = indexOfLastJob - itemsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  // Calculate total pages
  const totalPages = Math.ceil(filteredJobs.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Bannermain />
      <div className="bg-gray-100 min-h-screen px-[10%] py-10">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            {/* Job Category */}
            <div>
              <label className="block text-sm font-medium font-title text-gray-700">Job Category</label>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="mt-1 block w-full p-2 font-content border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select Category</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Administration">Administration</option>
                <option value="Pharmacy">Pharmacy</option>
                <option value="Management">Management</option>
              </select>
            </div>

            {/* Job Type */}
            <div>
              <label className="block text-sm font-medium font-title text-gray-700">Job Type</label>
              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="mt-1 block w-full font-content p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select Type</option>
                <option value="Full-Time">Full-Time</option>
                <option value="Part-Time">Part-Time</option>
                <option value="Contract">Contract</option>
                <option value="Internship">Internship</option>
                <option value="Temporary">Temporary</option>
              </select>
            </div>

            {/* Job Location */}
            <div>
              <label className="block text-sm font-medium font-title text-gray-700">Job Location</label>
              <select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="mt-1 block font-content w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select Location</option>
                <option value="Haridwar">Haridwar</option>
                <option value="Rishikesh">Rishikesh</option>
                <option value="Dehradun">Dehradun</option>
                <option value="Remote">Remote</option>
              </select>
            </div>
          </div>
        </div>

        {/* Job Listings */}
        <div className="mt-8">
          <div className="grid grid-cols-1 gap-6">
            {currentJobs.length > 0 ? (
              currentJobs.map((job) => (
                <Link to={`/jobdetails/${job.id}`} key={job.id} className="block hover:bg-gray-50 cursor-pointer">
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-bold text-primary font-title mb-2">{job.title}</h3>
                    <div className="flex justify-between items-start mb-4">
                      <div className="flex gap-4 font-subtitle text-gray-700 space-y-2">
                        <div className="flex items-center">
                          <FaBriefcase className="mr-2 text-secondary" />
                          <span>{job.experience}</span>
                        </div>
                        <div className="flex items-center">
                          <MdOutlineCategory className="mr-2 text-secondary" />
                          <span>{job.category}</span>
                        </div>
                        <div className="flex items-center">
                          <FaMapMarkerAlt className="mr-2 text-secondary" />
                          <span>{job.location}</span>
                        </div>
                      </div>
                      <div className="-mt-6 text-gray-700 space-y-2">
                        <div className="flex items-center">
                          <FaTags className="mr-2 text-secondary" />
                          <span>{job.type}</span>
                        </div>
                        <div className="flex items-center">
                          <FaCalendarAlt className="mr-2 text-secondary" />
                          <span>{job.deadline}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="text-center text-gray-600">
                No jobs available for the selected filters.
              </div>
            )}
          </div>
          
          {/* Pagination Controls */}
          <div className="flex justify-center mt-8">
            <nav>
              <ul className="flex space-x-2">
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 disabled:opacity-50"
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages).keys()].map((number) => (
                  <li key={number}>
                    <button
                      onClick={() => handlePageChange(number + 1)}
                      className={`px-4 py-2 rounded-md ${currentPage === number + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} hover:bg-gray-300`}
                    >
                      {number + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 disabled:opacity-50"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
