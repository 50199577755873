import React from 'react';
import { PiClipboardTextFill } from "react-icons/pi";
import { TbMessageQuestion } from "react-icons/tb";
import { LiaIdBadge } from "react-icons/lia";
import { GrHostMaintenance } from "react-icons/gr";

const cardData = [
  {
    icon: <PiClipboardTextFill />,
    title: "Health Assessment",
    description: "Comprehensive evaluation of your physical and mental health based on Ayurvedic principles. Identifies imbalances and determines your dosha profile.",
  },
  {
    icon: <TbMessageQuestion />,
    title: "Treatment Planning",
    description: "Customized plans tailored to your unique needs, incorporating herbal remedies, therapies, and lifestyle adjustments. Aims to restore balance and promote well-being.",
  },
  {
    icon: <LiaIdBadge />,
    title: "Therapeutic Interventions",
    description: "Application of specific Ayurvedic therapies such as Panchakarma, oil massages, and detox treatments. Focuses on healing and rejuvenation.",
  },
  {
    icon: <GrHostMaintenance />,
    title: "Wellness Monitoring",
    description: "Ongoing follow-ups to track progress and adjust treatment plans. Ensures long-term health improvements and sustained well-being.",
  },
];

const OurAyurvedicApproach = () => (
  <div className="lg:py-14">
    {/* Title and Description Section */}
    <div className="flex flex-col md:flex-row px-6 py-4 md:px-8 lg:px-20 items-start  mb-10 space-y-6 md:space-y-0">
      {/* Title Section */}
      <div className="w-full md:w-[45%] lg:w-[40%]">
        <h2 className="text-lg lg:text-md font-subtitle text-secondary  mb-2">
          Our Work Process
        </h2>
        <h3 className="text-3xl font-title font-bold text-primary">
          Our Ayurvedic Approach
        </h3>
      </div>

      {/* Description Section */}
      <div className="w-full md:w-[55%] lg:w-[60%]">
        <p className="font-content text-justify text-gray-600 text-base  leading-relaxed">
          At our Ayurveda center, we follow a unique and personalized approach to healing. Our expert practitioners begin each treatment process by conducting a thorough analysis of the patient’s body type, medical history, and current health conditions.
        </p>
      </div>
    </div>

    {/* Cards Section */}
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 lg:px-16">
      {cardData.map((card, index) => (
        <div
          key={index}
          className="flex flex-col items-center px-6 py-6 h-full hover:bg-white bg-gray-100 shadow-md transition-colors duration-300 rounded-lg"
        >
          <div className="w-12 h-12 bg-[#2e74b5] text-white rounded-full flex items-center justify-center text-2xl">
            {card.icon}
          </div>
          <h4 className="text-lg sm:text-xl text-center font-semibold text-gray-800 mt-4">
            {card.title}
          </h4>
          <p className="text-gray-600 text-justify mt-2 text-sm sm:text-base">
            {card.description}
          </p>
        </div>
      ))}
    </div>
  </div>
);

export default OurAyurvedicApproach;
