import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaRegUser } from "react-icons/fa";
import Bannermain from "../main/Mainbanner";
import sanityClient from "../SanityClient";
import { MdCategory } from "react-icons/md";
import { FaCircleLeft } from "react-icons/fa6";
import { Cta } from "../main/Cta";

const EventDetails = () => {
  const [eventData, setEventData] = useState(null);
  const navigate = useNavigate();
  const { eventslug } = useParams();

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });

    sanityClient
      .fetch(
        `*[_type == "events" && eventslug.current == ${JSON.stringify(eventslug)}]{
          eventdate,
          eventimage{
            asset->{
              _id,
              url
            }
          },
          authorname,
          category,
          title,
          discription,
          fblink,
          twitterlink,
          linkedinlink,
          eventslug
        }`
      )
      .then((data) => setEventData(data[0]))
      .catch(console.error);
  }, [eventslug]);

  if (!eventData) {
    return <div>Loading...</div>; // Show a loading state until event data is fetched
  }

  const eventImageUrl = eventData.eventimage?.asset?.url ; // Provide a fallback image

  return (
    <>
      <Bannermain />
      <div className="px-[1%] py-5 sm:px-6 lg:px-[8%] lg:py-8">
        <div className="flex flex-col lg:flex-row justify-center items-start h-full space-y-8 lg:space-y-0 lg:space-x-8">
          <div className="w-full px-2 lg:px-4 mb-8">
            <div
              className="relative bg-white md:p-6 p-2 rounded-2xl text-black shadow-2xl"
              data-aos="fade-up"
            >
              <div className="relative">
                <img
                  src={eventImageUrl}
                  alt={eventData.title}
                  className="w-full h-64 sm:h-56 lg:h-96 object-cover object-center"
                />
                <div className="absolute top-3 left-3 bg-white font-bold md:text-lg text-secondary text-xs p-2 rounded">
                  {eventData.eventdate}
                </div>
              </div>
              <div className="px-2 py-[3.5%] w-full">
                
                <h2 className="lg:text-3xl font-bold mb-4 lg:mb-6 md:text-4xl font-title text-[#2e74b5] text-base">
                  {eventData.title}
                </h2>
                <p className="text-justify font-subtitle font-light text-black">
                  {eventData.discription}
                </p>
                
                <button
                  onClick={() => navigate(-1)}
                  className="absolute top-[5%] right-[2%] bg-primary p-[2%] rounded-full shadow-lg cursor-pointer transition-all"
                >
                  <FaCircleLeft className="text-xl text-white hover:text-black" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cta/>
    </>
  );
};

export default EventDetails;
