import React, { useEffect, useState } from 'react';
import client from '../SanityClient'; // Adjust the path as needed
import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

export const OurTreatment = () => {
  const [treatments, setTreatments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const treatmentsPerPage = 3;

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      easing: 'ease-in-out', // Easing function for the animation
      once: true, // Whether animation should happen only once or every time
    });

    const fetchTreatments = async () => {
      const query = `*[_type == "treatmentDetails"]{
        _id,
        treatmentSlug,
        bannerImage {
          asset -> {
            url
          }
        },
        treatmentTitle,
        treatmentDescription,
        treatmentCategory
      }`;

      const data = await client.fetch(query);
      setTreatments(data);
    };

    fetchTreatments();
  }, []);

  const indexOfLastTreatment = currentPage * treatmentsPerPage;
  const indexOfFirstTreatment = indexOfLastTreatment - treatmentsPerPage;
  const currentTreatments = treatments.slice(indexOfFirstTreatment, indexOfLastTreatment);

  const totalPages = Math.ceil(treatments.length / treatmentsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();
  const PageNavigate = () => {
    navigate("/treatment");
  };

  return (
    <>
      <section className="py-12 bg-gray-100" data-aos="fade-right"> {/* Added AOS attribute */}
        <h1 className="text-lg md:text-2xl text-[#2e74b5]  text-center font-title">
          Our Ayurvedic Treatments
        </h1>
        <p className="text-xl md:text-4xl font-bold text-[#506D3F] text-center md:py-2 py-1 font-title">
          Enrich Your Health with Herbal
        </p>
        <p className="text-xs text-gray-800 py-1 md:text-base pb-6 p-3 md:p-0 text-center lg:mx-auto lg:w-1/2 font-subtitle">
          Ayurvedic treatments encompass a wide range of therapies and modalities. Here are some of the commonly practiced types of Ayurvedic treatments:
        </p>
        <div className="lg:px-[10%] py-5 px-[3%]">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {currentTreatments.map((treatment) => (
              <div key={treatment._id} className="bg-white shadow-md rounded-lg overflow-hidden" data-aos="fade-right"> {/* Added AOS attribute */}
                <img
                  src={treatment.bannerImage?.asset?.url || '/default-image.jpg'}
                  alt={treatment.treatmentTitle}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6 border-2 border-l-red-600">
                  <h3 className="text-lg md:text-xl font-semibold mb-2 font-title">
                    {treatment.treatmentTitle}
                  </h3>
                  <p className="text-gray-700 md:text-base text-xs font-subtitle line-clamp-2">
                    {treatment.treatmentDescription}
                  </p>
                  <Link to={`/treatments/${treatment?.treatmentSlug?.current}`}>
  <button
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    className="text-white bg-[#506D3F] px-3 py-1.5 rounded-md hover:bg-[#1c4f7d] transition duration-300 text-xs mt-4"
  >
    Know More
  </button>
</Link>

                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center" onClick={PageNavigate}>
          <button className="bg-[#506D3F] text-white text-sm md:text-base font-semibold py-2 px-6 rounded-lg shadow-lg hover:bg-[#92d14e] transition duration-300">
            View All
          </button>
        </div>
      </section>
    </>
  );
};
